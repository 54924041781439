@charset "UTF-8";

@font-face {
  font-family: "Orbitron-Black";
  src:url("./fonts/Orbitron-Medium.ttf");
  src:url("./fonts/Orbitron-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.digitos {
    display: inline-block;
    font: normal normal normal 14px/1 Orbitron-Black;
    color:blue;
    text-align: center;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }