/*
* ==========================================================
*     GENERAL
* ==========================================================
*/
.text-bold {
    font-weight: 700;
  }
  
  .text-small {
    font-size: 0.9rem;
  }
  
  .text-xsmall {
    font-size: 0.8rem;
  }
  
  .text-gray {
    color: #868e96;
  }
  
  .text-uppercase {
    letter-spacing: 0.2em;
  }
  
  .lh-2 {
    line-height: 2;
  }
  
  a {
    display: inline-block;
    text-decoration: none;
    color: #fff;
  }
  
  body {
    overflow-x: hidden;
  }
  
  i {
    display: inline-block;
  }
  
  span {
    display: inline-block;
  }
  
  .mt-30px {
    margin-top: 30px !important;
  }
  
  .mb-30px {
    margin-bottom: 30px !important;
  }
  
  i[class*='icon-'] {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }
  
  table {
    font-size: 0.9em;
    color: #666;
  }
  
  .btn-xs {
    font-size: 0.7em !important;
    padding: 2px 5px !important;
    border-radius: 2px !important;
  }
  
  .badge {
    font-weight: 400;
    border-radius: 0;
  }
  
  .badge.badge-primary {
    background: #379392;
  }
  
  .page {
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transition: width 0.3s linear;
    transition: width 0.3s linear;
    width: calc(100% - 200px);
    background-color: #F4F7FA;
    min-height: 100vh;
    padding-bottom: 50px;
  }
  
  .page.active {
    width: calc(100% - 70px);
  }
  
  h2 {
    color: #555;
    font-weight: 400;
  }
  
  .breadcrumb-holder {
    background: #eceeef;
  }
  
  .breadcrumb-holder .breadcrumb {
    padding: 15px 0;
    font-size: 0.85em;
    font-weight: 300;
    color: #999;
    margin-bottom: 0;
  }
  
  .breadcrumb-holder .breadcrumb a:hover, .breadcrumb-holder .breadcrumb:focus {
    text-decoration: none;
  }
  
  .breadcrumb-holder .breadcrumb .container {
    padding: 0;
  }
  
  .card {
    margin-bottom: 30px;
    -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  }
  
  .card-header h1,
  .card-header h2,
  .card-header h3,
  .card-header h4,
  .card-header h5,
  .card-header h6 {
    margin-bottom: 0;
  }
  
  .card:not([class*="text"]) p {
    color: #888;
  }
  
  .display {
    font-weight: 400 !important;
  }
  
  .section-padding {
    padding: 3rem 0;
  }
  
  .section-no-padding-bottom {
    padding-bottom: 0;
  }
  
  section header {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  
  @media (min-width: 768px) {
    .container-fluid {
      padding: 0 3rem;
    }
  }
  
  .list-group-item {
    border-right: 0;
    border-left: 0;
  }
  
  .list-group-item:first-child, .list-group-item:last-child {
    border-radius: 0;
  }
  
  .overflow-hidden {
    overflow: hidden;
  }
  
  .tile-link {
    position: absolute;
    cursor: pointer;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 30;
  }
/*
* ==========================================================
*     Main NAVBAR
* ==========================================================
*/
nav.navbar {
    background: #393836;
    padding: .8rem 0;
    /* Navbar Brand --------- */
    /*  Dropdowns ------------ */
    /* Toggle Button ---------- */
  }
  
  nav.navbar .side-navbar-wrapper {
    width: 100%;
    height: 100%;
  }
  
  nav.navbar .container-fluid {
    width: 100%;
  }
  
  nav.navbar .navbar-holder {
    width: 100%;
  }
  
  nav.navbar a {
    font-size: .8rem;
    color: #fff;
    position: relative;
  }
  
  nav.navbar .badge {
    position: absolute;
    top: 0;
    right: 0;
  }
  
  nav.navbar a.language span {
    margin-left: .2rem;
    vertical-align: middle;
  }
  
  nav.navbar a.logout i {
    margin-left: 5px;
  }
  
  nav.navbar small {
    font-size: 0.75rem;
    color: #999;
  }
  
  nav.navbar a.navbar-brand {
    margin-left: 10px;
  }
  
  nav.navbar .brand-text {
    text-transform: uppercase;
  }
  
  nav.navbar .brand-text strong {
    font-size: 1.5em;
  }
  
  nav.navbar .nav-menu {
    margin-bottom: 0;
  }
  
  nav.navbar .dropdown-toggle::after {
    border: none !important;
    content: '\f107';
    vertical-align: baseline;
    font-family: 'FontAwesome';
    margin-left: .3rem;
  }
  
  nav.navbar .dropdown-menu {
    right: 0;
    left: auto;
    float: left;
    margin-top: 16px;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1rem;
    padding: 1rem;
  }
  
  nav.navbar .dropdown-menu .dropdown-item {
    color: #666;
    padding: 10px;
  }
  
  nav.navbar .dropdown-menu .dropdown-item:active {
    color: #fff;
  }
  
  nav.navbar .dropdown-menu .dropdown-item:active span,
  nav.navbar .dropdown-menu .dropdown-item:active small {
    color: #fff;
  }
  
  nav.navbar .dropdown-menu .dropdown-item span {
    color: #777;
  }
  
  nav.navbar .dropdown-menu .dropdown-item i {
    margin-right: 5px;
  }
  
  nav.navbar .msg-body span,
  nav.navbar .msg-body strong {
    display: block;
  }
  
  nav.navbar .msg-body span {
    font-size: 0.85em;
  }
  
  nav.navbar .msg-profile {
    margin-right: 10px;
    width: 50px;
  }
  
  nav.navbar .notification-content {
    font-size: .8rem;
    margin-right: .5rem;
  }
  
  nav.navbar a.menu-btn {
    width: 40px;
    height: 40px;
    line-height: 40px;
    background: #292929;
    color: #fff;
    text-align: center;
    padding: 0 !important;
  }
  
  nav.navbar a.menu-btn i[class*='icon-'] {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }
  
  nav.navbar a.menu-btn:hover {
    background: #379392;
  }
  
  /*  NAVBAR MEDIAQUERIES ---------------------------------- */
  @media (max-width: 575px) {
    nav.navbar .dropdown {
      position: static;
    }
    nav.navbar .dropdown-menu {
      width: 90vw;
      right: 2.5vw;
      left: 2.5vw;
      margin: 0 auto;
    }
    nav.navbar .notification {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
    }
  }
  
  @media (min-width: 992px) {
    nav.navbar .dropdown-menu strong {
      font-weight: 400;
    }
    nav.navbar .dropdown-menu a {
      font-size: 0.9em;
    }
  }
  
/*
* ==========================================================
*     SIDE NAVBAR
* ==========================================================
*/
.side-navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 200px;
    height: 100%;
    width: 200px;
    text-align: left;
    background: #393836;
    color: #fff;
    z-index: 999;
    overflow-y: auto;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    /*  Side navbar small ------------------------ */
  }
  
  .side-navbar .scrolled-wrapper {
    width: 100%;
    height: 100%;
  }
  
  .side-navbar.hide {
    left: -70px;
  }
  
  .side-navbar a.brand-small {
    text-transform: uppercase;
    font-weight: 500;
    padding: 0;
  }
  
  .side-navbar a.brand-small:hover, .side-navbar a.brand-small:focus {
    background: none;
  }
  
  .side-navbar a.brand-small strong {
    font-size: 1.5rem;
  }
  
  .side-navbar li {
    position: relative;
  }
  
  .side-navbar li a {
    position: relative;
    font-size: .9rem;
    font-weight: 300;
    color: #fff;
    text-decoration: none;
    width: 100%;
    padding: 12px;
  }
  
  .side-navbar li a:focus, .side-navbar li a:hover, .side-navbar li a[aria-expanded="true"] {
    background: #379392;
    color: #fff;
    text-decoration: none;
  }
  
  .side-navbar li a[data-toggle="collapse"]::before {
    content: '\f104';
    display: inline-block;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    font-family: 'FontAwesome';
    position: absolute;
    top: 50%;
    right: 20px;
  }
  
  .side-navbar li a[aria-expanded="true"]::before {
    content: '\f107';
    color: #fff;
  }
  
  .side-navbar li.active > a {
    background: #379392;
  }
  
  .side-navbar li li.active > a {
    background: #308080 !important;
  }
  
  .side-navbar li ul {
    background: #292929;
    position: relative;
    min-width: 100%;
    left: 0;
    padding-left: 0;
  }
  
  .side-navbar li ul a {
    padding-left: 20px;
    font-size: .85rem;
  }
  
  .side-navbar li ul.collapse a {
    border-left: 2px solid #379392;
  }
  
  .side-navbar li ul.collapse a:hover {
    background: #121212;
  }
  
  .side-navbar .sidenav-header {
    background: #292929;
    padding: 14px;
    margin-bottom: 10px;
  }
  
  .side-navbar .sidenav-header img {
    width: 50px;
    height: 50px;
  }
  
  .side-navbar .sidenav-header span {
    font-size: 0.8em;
    color: #666;
  }
  
  .side-navbar .sidenav-header h2 {
    margin: 10px 0 0;
    text-transform: uppercase;
    letter-spacing: .1em;
  }
  
  .side-navbar .sidenav-heading {
    font-weight: 700;
    color: #adb5bd;
    font-size: .9rem;
    text-transform: uppercase;
    letter-spacing: .1em;
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .side-navbar i[class*='icon-'] {
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
  }
  
  .side-navbar span {
    display: inline-block;
  }
  
  .side-navbar i {
    font-size: inherit;
    margin-right: 10px;
  }
  
  .side-navbar .badge {
    display: inline-block;
    font-size: 0.6em;
    vertical-align: middle;
  }
  
  .side-navbar .brand-small {
    display: none;
  }
  
  .side-navbar.shrink {
    width: 70px;
    text-align: center;
  }
  
  .side-navbar.shrink .brand-small {
    display: block;
  }
  
  .side-navbar.shrink .sidenav-header-inner {
    display: none;
  }
  
  .side-navbar.shrink .sidenav-heading {
    margin-left: 0;
    font-size: .8rem;
  }
  
  .side-navbar.shrink ul li a {
    font-size: .7rem;
    padding-left: 5px;
    padding-right: 5px;
  }
  
  .side-navbar.shrink ul li a[data-toggle="collapse"]::before {
    content: '\f107';
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
    position: absolute;
    top: auto;
    right: 50%;
    bottom: 0;
    left: auto;
  }
  
  .side-navbar.shrink ul li a[data-toggle="collapse"][aria-expanded="true"]::before {
    content: '\f106';
  }
  
  .side-navbar.shrink ul li ul a {
    padding-left: 10px;
    font-size: .7rem;
  }
  
  .side-navbar.shrink i {
    display: block;
    margin-right: 0;
    font-size: 1rem;
  }
  
  .side-navbar.shrink .arrow {
    float: none;
    -webkit-transform: none !important;
    transform: none !important;
  }
  
  .side-navbar.shrink .badge {
    display: none;
  }
  
  /* SIDE NAVBAR MEDIAQUERIES ------------------------------ */
  @media (max-width: 1199px) {
    .page {
      width: 100%;
    }
    .page.active {
      width: 100%;
    }
    .page.active-sm {
      width: calc(100% - 70px);
    }
    .side-navbar {
      left: -70px;
      width: 70px;
      text-align: center;
    }
    .side-navbar.show-sm {
      left: 0 !important;
    }
    .side-navbar .brand-small {
      display: block;
    }
    .side-navbar .sidenav-header-inner {
      display: none;
    }
    .side-navbar .sidenav-heading {
      margin-left: 0;
      font-size: .8rem;
    }
    .side-navbar ul li a {
      font-size: .7rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .side-navbar ul li a[data-toggle="collapse"]::before {
      content: '\f107';
      -webkit-transform: translateX(50%);
      transform: translateX(50%);
      position: absolute;
      top: auto;
      right: 50%;
      bottom: 0;
      left: auto;
    }
    .side-navbar ul li a[data-toggle="collapse"][aria-expanded="true"]::before {
      content: '\f106';
    }
    .side-navbar ul li ul a {
      padding-left: 10px;
      font-size: .7rem;
    }
    .side-navbar i {
      display: block;
      margin-right: 0;
      font-size: 1rem;
    }
    .side-navbar .arrow {
      float: none;
      -webkit-transform: none !important;
      transform: none !important;
    }
    .side-navbar .badge {
      display: none;
    }
  }
  
  .gmpc{position:relative}.gmpc-percent-text{position:absolute;width:100%;top:50%;left:50%;transform:translate(-50%,-50%);text-align:center}

  /* 
------------------------------------------------------------------------------------------------------------------------
1. BASIC STYLE  
------------------------------------------------------------------------------------------------------------------------
*/

	.mCustomScrollbar{ -ms-touch-action: pinch-zoom; touch-action: pinch-zoom; /* direct pointer events to js */ }
	.mCustomScrollbar.mCS_no_scrollbar, .mCustomScrollbar.mCS_touch_action{ -ms-touch-action: auto; touch-action: auto; }
	
	.mCustomScrollBox{ /* contains plugin's markup */
		position: relative;
		overflow: hidden;
		height: 100%;
		max-width: 100%;
		outline: none;
		direction: ltr;
	}

	.mCSB_container{ /* contains the original content */
		overflow: hidden;
		width: auto;
		height: auto;
	}



/* 
------------------------------------------------------------------------------------------------------------------------
2. VERTICAL SCROLLBAR 
y-axis
------------------------------------------------------------------------------------------------------------------------
*/

	.mCSB_inside > .mCSB_container{ margin-right: 30px; }

	.mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden{ margin-right: 0; } /* non-visible scrollbar */
	
	.mCS-dir-rtl > .mCSB_inside > .mCSB_container{ /* RTL direction/left-side scrollbar */
		margin-right: 0;
		margin-left: 30px;
	}
	
	.mCS-dir-rtl > .mCSB_inside > .mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden{ margin-left: 0; } /* RTL direction/left-side scrollbar */

	.mCSB_scrollTools{ /* contains scrollbar markup (draggable element, dragger rail, buttons etc.) */
		position: absolute;
		width: 16px;
		height: auto;
		left: auto;
		top: 0;
		right: 0;
		bottom: 0;
	}

	.mCSB_outside + .mCSB_scrollTools{ right: -26px; } /* scrollbar position: outside */
	
	.mCS-dir-rtl > .mCSB_inside > .mCSB_scrollTools, 
	.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools{ /* RTL direction/left-side scrollbar */
		right: auto;
		left: 0;
	}
	
	.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools{ left: -26px; } /* RTL direction/left-side scrollbar (scrollbar position: outside) */

	.mCSB_scrollTools .mCSB_draggerContainer{ /* contains the draggable element and dragger rail markup */
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0; 
		height: auto;
	}

	.mCSB_scrollTools a + .mCSB_draggerContainer{ margin: 20px 0; }

	.mCSB_scrollTools .mCSB_draggerRail{
		width: 2px;
		height: 100%;
		margin: 0 auto;
		-webkit-border-radius: 16px; -moz-border-radius: 16px; border-radius: 16px;
	}

	.mCSB_scrollTools .mCSB_dragger{ /* the draggable element */
		cursor: pointer;
		width: 100%;
		height: 30px; /* minimum dragger height */
		z-index: 1;
	}

	.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{ /* the dragger element */
		position: relative;
		width: 4px;
		height: 100%;
		margin: 0 auto;
		-webkit-border-radius: 16px; -moz-border-radius: 16px; border-radius: 16px;
		text-align: center;
	}
	
	.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, 
	.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar{ width: 12px; /* auto-expanded scrollbar */ }
	
	.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, 
	.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail{ width: 8px; /* auto-expanded scrollbar */ }

	.mCSB_scrollTools .mCSB_buttonUp,
	.mCSB_scrollTools .mCSB_buttonDown{
		display: block;
		position: absolute;
		height: 20px;
		width: 100%;
		overflow: hidden;
		margin: 0 auto;
		cursor: pointer;
	}

	.mCSB_scrollTools .mCSB_buttonDown{ bottom: 0; }



/* 
------------------------------------------------------------------------------------------------------------------------
3. HORIZONTAL SCROLLBAR 
x-axis
------------------------------------------------------------------------------------------------------------------------
*/

	.mCSB_horizontal.mCSB_inside > .mCSB_container{
		margin-right: 0;
		margin-bottom: 30px;
	}
	
	.mCSB_horizontal.mCSB_outside > .mCSB_container{ min-height: 100%; }

	.mCSB_horizontal > .mCSB_container.mCS_no_scrollbar_x.mCS_x_hidden{ margin-bottom: 0; } /* non-visible scrollbar */

	.mCSB_scrollTools.mCSB_scrollTools_horizontal{
		width: auto;
		height: 16px;
		top: auto;
		right: 0;
		bottom: 0;
		left: 0;
	}

	.mCustomScrollBox + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
	.mCustomScrollBox + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal{ bottom: -26px; } /* scrollbar position: outside */

	.mCSB_scrollTools.mCSB_scrollTools_horizontal a + .mCSB_draggerContainer{ margin: 0 20px; }

	.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail{
		width: 100%;
		height: 2px;
		margin: 7px 0;
	}

	.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger{
		width: 30px; /* minimum dragger width */
		height: 100%;
		left: 0;
	}

	.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar{
		width: 100%;
		height: 4px;
		margin: 6px auto;
	}
	
	.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, 
	.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar{
		height: 12px; /* auto-expanded scrollbar */
		margin: 2px auto;
	}
	
	.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, 
	.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail{
		height: 8px; /* auto-expanded scrollbar */
		margin: 4px 0;
	}

	.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft,
	.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight{
		display: block;
		position: absolute;
		width: 20px;
		height: 100%;
		overflow: hidden;
		margin: 0 auto;
		cursor: pointer;
	}
	
	.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft{ left: 0; }

	.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight{ right: 0; }



/* 
------------------------------------------------------------------------------------------------------------------------
4. VERTICAL AND HORIZONTAL SCROLLBARS 
yx-axis 
------------------------------------------------------------------------------------------------------------------------
*/

	.mCSB_container_wrapper{
		position: absolute;
		height: auto;
		width: auto;
		overflow: hidden;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin-right: 30px;
		margin-bottom: 30px;
	}
	
	.mCSB_container_wrapper > .mCSB_container{
		padding-right: 30px;
		padding-bottom: 30px;
		-webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box;
	}
	
	.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_vertical{ bottom: 20px; }
	
	.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_horizontal{ right: 20px; }
	
	/* non-visible horizontal scrollbar */
	.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden + .mCSB_scrollTools.mCSB_scrollTools_vertical{ bottom: 0; }
	
	/* non-visible vertical scrollbar/RTL direction/left-side scrollbar */
	.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal, 
	.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal{ right: 0; }
	
	/* RTL direction/left-side scrollbar */
	.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal{ left: 20px; }
	
	/* non-visible scrollbar/RTL direction/left-side scrollbar */
	.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal{ left: 0; }
	
	.mCS-dir-rtl > .mCSB_inside > .mCSB_container_wrapper{ /* RTL direction/left-side scrollbar */
		margin-right: 0;
		margin-left: 30px;
	}
	
	.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden > .mCSB_container{ padding-right: 0; }
	
	.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden > .mCSB_container{ padding-bottom: 0; }
	
	.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden{
		margin-right: 0; /* non-visible scrollbar */
		margin-left: 0;
	}
	
	/* non-visible horizontal scrollbar */
	.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden{ margin-bottom: 0; }



/* 
------------------------------------------------------------------------------------------------------------------------
5. TRANSITIONS  
------------------------------------------------------------------------------------------------------------------------
*/

	.mCSB_scrollTools, 
	.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, 
	.mCSB_scrollTools .mCSB_buttonUp,
	.mCSB_scrollTools .mCSB_buttonDown,
	.mCSB_scrollTools .mCSB_buttonLeft,
	.mCSB_scrollTools .mCSB_buttonRight{
		-webkit-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
		-moz-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
		-o-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
		transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
	}
	
	.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar, /* auto-expanded scrollbar */
	.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail, 
	.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar, 
	.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail{
		-webkit-transition: width .2s ease-out .2s, height .2s ease-out .2s, 
					margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, 
					margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s,
					opacity .2s ease-in-out, background-color .2s ease-in-out; 
		-moz-transition: width .2s ease-out .2s, height .2s ease-out .2s, 
					margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, 
					margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s,
					opacity .2s ease-in-out, background-color .2s ease-in-out; 
		-o-transition: width .2s ease-out .2s, height .2s ease-out .2s, 
					margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, 
					margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s,
					opacity .2s ease-in-out, background-color .2s ease-in-out; 
		transition: width .2s ease-out .2s, height .2s ease-out .2s, 
					margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, 
					margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s,
					opacity .2s ease-in-out, background-color .2s ease-in-out; 
	}



/* 
------------------------------------------------------------------------------------------------------------------------
6. SCROLLBAR COLORS, OPACITY AND BACKGROUNDS  
------------------------------------------------------------------------------------------------------------------------
*/

	/* 
	----------------------------------------
	6.1 THEMES 
	----------------------------------------
	*/
	
	/* default theme ("light") */

	.mCSB_scrollTools{ opacity: 0.75; filter: "alpha(opacity=75)"; -ms-filter: "alpha(opacity=75)"; }
	
	.mCS-autoHide > .mCustomScrollBox > .mCSB_scrollTools,
	.mCS-autoHide > .mCustomScrollBox ~ .mCSB_scrollTools{ opacity: 0; filter: "alpha(opacity=0)"; -ms-filter: "alpha(opacity=0)"; }
	
	.mCustomScrollbar > .mCustomScrollBox > .mCSB_scrollTools.mCSB_scrollTools_onDrag,
	.mCustomScrollbar > .mCustomScrollBox ~ .mCSB_scrollTools.mCSB_scrollTools_onDrag,
	.mCustomScrollBox:hover > .mCSB_scrollTools,
	.mCustomScrollBox:hover ~ .mCSB_scrollTools,
	.mCS-autoHide:hover > .mCustomScrollBox > .mCSB_scrollTools,
	.mCS-autoHide:hover > .mCustomScrollBox ~ .mCSB_scrollTools{ opacity: 1; filter: "alpha(opacity=100)"; -ms-filter: "alpha(opacity=100)"; }

	.mCSB_scrollTools .mCSB_draggerRail{
		background-color: #000; background-color: rgba(0,0,0,0.4);
		filter: "alpha(opacity=40)"; -ms-filter: "alpha(opacity=40)"; 
	}

	.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
		background-color: #fff; background-color: rgba(255,255,255,0.75);
		filter: "alpha(opacity=75)"; -ms-filter: "alpha(opacity=75)"; 
	}

	.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar{
		background-color: #fff; background-color: rgba(255,255,255,0.85);
		filter: "alpha(opacity=85)"; -ms-filter: "alpha(opacity=85)"; 
	}
	.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
	.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar{
		background-color: #fff; background-color: rgba(255,255,255,0.9);
		filter: "alpha(opacity=90)"; -ms-filter: "alpha(opacity=90)"; 
	}

	.mCSB_scrollTools .mCSB_buttonUp,
	.mCSB_scrollTools .mCSB_buttonDown,
	.mCSB_scrollTools .mCSB_buttonLeft,
	.mCSB_scrollTools .mCSB_buttonRight{
		background-image: url(mCSB_buttons.png); /* css sprites */
		background-repeat: no-repeat;
		opacity: 0.4; filter: "alpha(opacity=40)"; -ms-filter: "alpha(opacity=40)"; 
	}

	.mCSB_scrollTools .mCSB_buttonUp{
		background-position: 0 0;
		/* 
		sprites locations 
		light: 0 0, -16px 0, -32px 0, -48px 0, 0 -72px, -16px -72px, -32px -72px
		dark: -80px 0, -96px 0, -112px 0, -128px 0, -80px -72px, -96px -72px, -112px -72px
		*/
	}

	.mCSB_scrollTools .mCSB_buttonDown{
		background-position: 0 -20px;
		/* 
		sprites locations
		light: 0 -20px, -16px -20px, -32px -20px, -48px -20px, 0 -92px, -16px -92px, -32px -92px
		dark: -80px -20px, -96px -20px, -112px -20px, -128px -20px, -80px -92px, -96px -92px, -112 -92px
		*/
	}

	.mCSB_scrollTools .mCSB_buttonLeft{
		background-position: 0 -40px;
		/* 
		sprites locations 
		light: 0 -40px, -20px -40px, -40px -40px, -60px -40px, 0 -112px, -20px -112px, -40px -112px
		dark: -80px -40px, -100px -40px, -120px -40px, -140px -40px, -80px -112px, -100px -112px, -120px -112px
		*/
	}

	.mCSB_scrollTools .mCSB_buttonRight{
		background-position: 0 -56px;
		/* 
		sprites locations 
		light: 0 -56px, -20px -56px, -40px -56px, -60px -56px, 0 -128px, -20px -128px, -40px -128px
		dark: -80px -56px, -100px -56px, -120px -56px, -140px -56px, -80px -128px, -100px -128px, -120px -128px
		*/
	}

	.mCSB_scrollTools .mCSB_buttonUp:hover,
	.mCSB_scrollTools .mCSB_buttonDown:hover,
	.mCSB_scrollTools .mCSB_buttonLeft:hover,
	.mCSB_scrollTools .mCSB_buttonRight:hover{ opacity: 0.75; filter: "alpha(opacity=75)"; -ms-filter: "alpha(opacity=75)"; }

	.mCSB_scrollTools .mCSB_buttonUp:active,
	.mCSB_scrollTools .mCSB_buttonDown:active,
	.mCSB_scrollTools .mCSB_buttonLeft:active,
	.mCSB_scrollTools .mCSB_buttonRight:active{ opacity: 0.9; filter: "alpha(opacity=90)"; -ms-filter: "alpha(opacity=90)"; }
	

	/* theme: "dark" */

	.mCS-dark.mCSB_scrollTools .mCSB_draggerRail{ background-color: #000; background-color: rgba(0,0,0,0.15); }

	.mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{ background-color: #000; background-color: rgba(0,0,0,0.75); }

	.mCS-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar{ background-color: rgba(0,0,0,0.85); }

	.mCS-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
	.mCS-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar{ background-color: rgba(0,0,0,0.9); }

	.mCS-dark.mCSB_scrollTools .mCSB_buttonUp{ background-position: -80px 0; }

	.mCS-dark.mCSB_scrollTools .mCSB_buttonDown{ background-position: -80px -20px; }

	.mCS-dark.mCSB_scrollTools .mCSB_buttonLeft{ background-position: -80px -40px; }

	.mCS-dark.mCSB_scrollTools .mCSB_buttonRight{ background-position: -80px -56px; }
	
	/* ---------------------------------------- */
	


	/* theme: "light-2", "dark-2" */

	.mCS-light-2.mCSB_scrollTools .mCSB_draggerRail, 
	.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail{
		width: 4px;
		background-color: #fff; background-color: rgba(255,255,255,0.1);
		-webkit-border-radius: 1px; -moz-border-radius: 1px; border-radius: 1px;
	}

	.mCS-light-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, 
	.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
		width: 4px;
		background-color: #fff; background-color: rgba(255,255,255,0.75);
		-webkit-border-radius: 1px; -moz-border-radius: 1px; border-radius: 1px;
	}

	.mCS-light-2.mCSB_scrollTools_horizontal .mCSB_draggerRail, 
	.mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_draggerRail, 
	.mCS-light-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, 
	.mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar{
		width: 100%;
		height: 4px;
		margin: 6px auto;
	}

	.mCS-light-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar{ background-color: #fff; background-color: rgba(255,255,255,0.85); }

	.mCS-light-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
	.mCS-light-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar{ background-color: #fff; background-color: rgba(255,255,255,0.9); }

	.mCS-light-2.mCSB_scrollTools .mCSB_buttonUp{ background-position: -32px 0; }

	.mCS-light-2.mCSB_scrollTools .mCSB_buttonDown{	background-position: -32px -20px; }

	.mCS-light-2.mCSB_scrollTools .mCSB_buttonLeft{	background-position: -40px -40px; }

	.mCS-light-2.mCSB_scrollTools .mCSB_buttonRight{ background-position: -40px -56px; }
	
	
	/* theme: "dark-2" */

	.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail{
		background-color: #000; background-color: rgba(0,0,0,0.1);
		-webkit-border-radius: 1px; -moz-border-radius: 1px; border-radius: 1px;
	}

	.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
		background-color: #000; background-color: rgba(0,0,0,0.75);
		-webkit-border-radius: 1px; -moz-border-radius: 1px; border-radius: 1px;
	}

	.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar{ background-color: #000; background-color: rgba(0,0,0,0.85); }

	.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
	.mCS-dark-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar{ background-color: #000; background-color: rgba(0,0,0,0.9); }

	.mCS-dark-2.mCSB_scrollTools .mCSB_buttonUp{ background-position: -112px 0; }

	.mCS-dark-2.mCSB_scrollTools .mCSB_buttonDown{ background-position: -112px -20px; }

	.mCS-dark-2.mCSB_scrollTools .mCSB_buttonLeft{ background-position: -120px -40px; }

	.mCS-dark-2.mCSB_scrollTools .mCSB_buttonRight{	background-position: -120px -56px; }
	
	/* ---------------------------------------- */
	


	/* theme: "light-thick", "dark-thick" */

	.mCS-light-thick.mCSB_scrollTools .mCSB_draggerRail, 
	.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail{
		width: 4px;
		background-color: #fff; background-color: rgba(255,255,255,0.1);
		-webkit-border-radius: 2px; -moz-border-radius: 2px; border-radius: 2px;
	}

	.mCS-light-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, 
	.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
		width: 6px;
		background-color: #fff; background-color: rgba(255,255,255,0.75);
		-webkit-border-radius: 2px; -moz-border-radius: 2px; border-radius: 2px;
	}

	.mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail, 
	.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail{
		width: 100%;
		height: 4px;
		margin: 6px 0;
	}

	.mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, 
	.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar{
		width: 100%;
		height: 6px;
		margin: 5px auto;
	}

	.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar{ background-color: #fff; background-color: rgba(255,255,255,0.85); }

	.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
	.mCS-light-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar{ background-color: #fff; background-color: rgba(255,255,255,0.9); }

	.mCS-light-thick.mCSB_scrollTools .mCSB_buttonUp{ background-position: -16px 0; }

	.mCS-light-thick.mCSB_scrollTools .mCSB_buttonDown{	background-position: -16px -20px; }

	.mCS-light-thick.mCSB_scrollTools .mCSB_buttonLeft{	background-position: -20px -40px; }

	.mCS-light-thick.mCSB_scrollTools .mCSB_buttonRight{ background-position: -20px -56px; }


	/* theme: "dark-thick" */
	
	.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail{
		background-color: #000; background-color: rgba(0,0,0,0.1);
		-webkit-border-radius: 2px; -moz-border-radius: 2px; border-radius: 2px;
	}

	.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
		background-color: #000; background-color: rgba(0,0,0,0.75);
		-webkit-border-radius: 2px; -moz-border-radius: 2px; border-radius: 2px;
	}

	.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar{ background-color: #000; background-color: rgba(0,0,0,0.85); }

	.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
	.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar{ background-color: #000; background-color: rgba(0,0,0,0.9); }

	.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonUp{ background-position: -96px 0; }

	.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonDown{ background-position: -96px -20px; }

	.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonLeft{ background-position: -100px -40px; }

	.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonRight{	background-position: -100px -56px; }
	
	/* ---------------------------------------- */
	


	/* theme: "light-thin", "dark-thin" */
	
	.mCS-light-thin.mCSB_scrollTools .mCSB_draggerRail{ background-color: #fff; background-color: rgba(255,255,255,0.1); }

	.mCS-light-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, 
	.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{ width: 2px; }

	.mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail, 
	.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail{ width: 100%; }

	.mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, 
	.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar{
		width: 100%;
		height: 2px;
		margin: 7px auto;
	}


	/* theme "dark-thin" */
	
	.mCS-dark-thin.mCSB_scrollTools .mCSB_draggerRail{ background-color: #000; background-color: rgba(0,0,0,0.15); }

	.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{ background-color: #000; background-color: rgba(0,0,0,0.75); }
	
	.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar{ background-color: #000; background-color: rgba(0,0,0,0.85); }
	
	.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
	.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar{ background-color: #000; background-color: rgba(0,0,0,0.9); }
	
	.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonUp{	background-position: -80px 0; }

	.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonDown{ background-position: -80px -20px; }

	.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonLeft{ background-position: -80px -40px; }

	.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonRight{ background-position: -80px -56px; }
	
	/* ---------------------------------------- */
	
	
	
	/* theme "rounded", "rounded-dark", "rounded-dots", "rounded-dots-dark" */
	
	.mCS-rounded.mCSB_scrollTools .mCSB_draggerRail{ background-color: #fff; background-color: rgba(255,255,255,0.15); }
	
	.mCS-rounded.mCSB_scrollTools .mCSB_dragger, 
	.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger, 
	.mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger, 
	.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger{ height: 14px; }
	
	.mCS-rounded.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, 
	.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, 
	.mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, 
	.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
		width: 14px;
		margin: 0 1px;
	}
	
	.mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger, 
	.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger, 
	.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger, 
	.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger{ width: 14px; }
	
	.mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, 
	.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, 
	.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, 
	.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar{
		height: 14px;
		margin: 1px 0;
	}
	
	.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, 
	.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar, 
	.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, 
	.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar{
		width: 16px; /* auto-expanded scrollbar */
		height: 16px;
		margin: -1px 0;
	}
	
	.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, 
	.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail, 
	.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, 
	.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail{ width: 4px; /* auto-expanded scrollbar */ }
	
	.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, 
	.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar, 
	.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, 
	.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar{
		height: 16px; /* auto-expanded scrollbar */
		width: 16px;
		margin: 0 -1px;
	}
	
	.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, 
	.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail, 
	.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, 
	.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail{
		height: 4px; /* auto-expanded scrollbar */
		margin: 6px 0;
	}
	
	.mCS-rounded.mCSB_scrollTools .mCSB_buttonUp{ background-position: 0 -72px; }
	
	.mCS-rounded.mCSB_scrollTools .mCSB_buttonDown{ background-position: 0 -92px; }
	
	.mCS-rounded.mCSB_scrollTools .mCSB_buttonLeft{ background-position: 0 -112px; }
	
	.mCS-rounded.mCSB_scrollTools .mCSB_buttonRight{ background-position: 0 -128px; }
	
	
	/* theme "rounded-dark", "rounded-dots-dark" */
	
	.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, 
	.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{ background-color: #000; background-color: rgba(0,0,0,0.75); }
	
	.mCS-rounded-dark.mCSB_scrollTools .mCSB_draggerRail{ background-color: #000; background-color: rgba(0,0,0,0.15); }
	
	.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar, 
	.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar{ background-color: #000; background-color: rgba(0,0,0,0.85); }
	
	.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
	.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, 
	.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
	.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar{ background-color: #000; background-color: rgba(0,0,0,0.9); }
	
	.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonUp{ background-position: -80px -72px; }
	
	.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonDown{ background-position: -80px -92px; }
	
	.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonLeft{ background-position: -80px -112px; }
	
	.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonRight{ background-position: -80px -128px; }
	
	
	/* theme "rounded-dots", "rounded-dots-dark" */
	
	.mCS-rounded-dots.mCSB_scrollTools_vertical .mCSB_draggerRail, 
	.mCS-rounded-dots-dark.mCSB_scrollTools_vertical .mCSB_draggerRail{ width: 4px; }
	
	.mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail, 
	.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail, 
	.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail, 
	.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail{
		background-color: transparent;
		background-position: center;
	}
	
	.mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail, 
	.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail{
		background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAANElEQVQYV2NkIAAYiVbw//9/Y6DiM1ANJoyMjGdBbLgJQAX/kU0DKgDLkaQAvxW4HEvQFwCRcxIJK1XznAAAAABJRU5ErkJggg==");
		background-repeat: repeat-y;
		opacity: 0.3;
		filter: "alpha(opacity=30)"; -ms-filter: "alpha(opacity=30)"; 
	}
	
	.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail, 
	.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail{
		height: 4px;
		margin: 6px 0;
		background-repeat: repeat-x;
	}
	
	.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonUp{ background-position: -16px -72px; }
	
	.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonDown{ background-position: -16px -92px; }
	
	.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonLeft{ background-position: -20px -112px; }
	
	.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonRight{ background-position: -20px -128px; }
	
	
	/* theme "rounded-dots-dark" */
	
	.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail{
		background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAALElEQVQYV2NkIAAYSVFgDFR8BqrBBEifBbGRTfiPZhpYjiQFBK3A6l6CvgAAE9kGCd1mvgEAAAAASUVORK5CYII=");
	}
	
	.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonUp{ background-position: -96px -72px; }
	
	.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonDown{ background-position: -96px -92px; }
	
	.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonLeft{ background-position: -100px -112px; }
	
	.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonRight{ background-position: -100px -128px; }
	
	/* ---------------------------------------- */
	
	
	
	/* theme "3d", "3d-dark", "3d-thick", "3d-thick-dark" */
	
	.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, 
	.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, 
	.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, 
	.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
		background-repeat: repeat-y;
		background-image: -moz-linear-gradient(left, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0) 100%);
		background-image: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,0.5)), color-stop(100%,rgba(255,255,255,0)));
		background-image: -webkit-linear-gradient(left, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
		background-image: -o-linear-gradient(left, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
		background-image: -ms-linear-gradient(left, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
		background-image: linear-gradient(to right, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
	}
	
	.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, 
	.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, 
	.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, 
	.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar{
		background-repeat: repeat-x;
		background-image: -moz-linear-gradient(top, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0) 100%);
		background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,0.5)), color-stop(100%,rgba(255,255,255,0)));
		background-image: -webkit-linear-gradient(top, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
		background-image: -o-linear-gradient(top, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
		background-image: -ms-linear-gradient(top, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
		background-image: linear-gradient(to bottom, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
	}
	
	
	/* theme "3d", "3d-dark" */
	
	.mCS-3d.mCSB_scrollTools_vertical .mCSB_dragger, 
	.mCS-3d-dark.mCSB_scrollTools_vertical .mCSB_dragger{ height: 70px; }
	
	.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger, 
	.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger{ width: 70px; }
	
	.mCS-3d.mCSB_scrollTools, 
	.mCS-3d-dark.mCSB_scrollTools{
		opacity: 1;
		filter: "alpha(opacity=30)"; -ms-filter: "alpha(opacity=30)"; 
	}
	
	.mCS-3d.mCSB_scrollTools .mCSB_draggerRail, 
	.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, 
	.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail, 
	.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{ -webkit-border-radius: 16px; -moz-border-radius: 16px; border-radius: 16px; }
	
	.mCS-3d.mCSB_scrollTools .mCSB_draggerRail, 
	.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail{
		width: 8px;
		background-color: #000; background-color: rgba(0,0,0,0.2);
		box-shadow: inset 1px 0 1px rgba(0,0,0,0.5), inset -1px 0 1px rgba(255,255,255,0.2);
	}
	
	.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, 	 
	.mCS-3d.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar, 
	.mCS-3d.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
	.mCS-3d.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, 
	.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, 
	.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar, 
	.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
	.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar{ background-color: #555; }

	.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, 
	.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{ width: 8px; }

	.mCS-3d.mCSB_scrollTools_horizontal .mCSB_draggerRail, 
	.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail{
		width: 100%;
		height: 8px;
		margin: 4px 0;
		box-shadow: inset 0 1px 1px rgba(0,0,0,0.5), inset 0 -1px 1px rgba(255,255,255,0.2);
	}

	.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, 
	.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar{
		width: 100%;
		height: 8px;
		margin: 4px auto;
	}
	
	.mCS-3d.mCSB_scrollTools .mCSB_buttonUp{ background-position: -32px -72px; }
	
	.mCS-3d.mCSB_scrollTools .mCSB_buttonDown{ background-position: -32px -92px; }
	
	.mCS-3d.mCSB_scrollTools .mCSB_buttonLeft{ background-position: -40px -112px; }
	
	.mCS-3d.mCSB_scrollTools .mCSB_buttonRight{ background-position: -40px -128px; }
	
	
	/* theme "3d-dark" */
	
	.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail{
		background-color: #000; background-color: rgba(0,0,0,0.1);
		box-shadow: inset 1px 0 1px rgba(0,0,0,0.1);
	}
	
	.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail{ box-shadow: inset 0 1px 1px rgba(0,0,0,0.1); }
	
	.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonUp{ background-position: -112px -72px; }

	.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonDown{ background-position: -112px -92px; }

	.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonLeft{ background-position: -120px -112px; }

	.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonRight{	background-position: -120px -128px; }
	
	/* ---------------------------------------- */
	
	
	
	/* theme: "3d-thick", "3d-thick-dark" */
	
	.mCS-3d-thick.mCSB_scrollTools, 
	.mCS-3d-thick-dark.mCSB_scrollTools{
		opacity: 1;
		filter: "alpha(opacity=30)"; -ms-filter: "alpha(opacity=30)"; 
	}
	
	.mCS-3d-thick.mCSB_scrollTools, 
	.mCS-3d-thick-dark.mCSB_scrollTools, 
	.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer, 
	.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer{ -webkit-border-radius: 7px; -moz-border-radius: 7px; border-radius: 7px; }
	
	.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, 
	.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{ -webkit-border-radius: 5px; -moz-border-radius: 5px; border-radius: 5px; }
	
	.mCSB_inside + .mCS-3d-thick.mCSB_scrollTools_vertical, 
	.mCSB_inside + .mCS-3d-thick-dark.mCSB_scrollTools_vertical{ right: 1px; }
	
	.mCS-3d-thick.mCSB_scrollTools_vertical, 
	.mCS-3d-thick-dark.mCSB_scrollTools_vertical{ box-shadow: inset 1px 0 1px rgba(0,0,0,0.1), inset 0 0 14px rgba(0,0,0,0.5); }
	
	.mCS-3d-thick.mCSB_scrollTools_horizontal, 
	.mCS-3d-thick-dark.mCSB_scrollTools_horizontal{
		bottom: 1px;
		box-shadow: inset 0 1px 1px rgba(0,0,0,0.1), inset 0 0 14px rgba(0,0,0,0.5);
	}
	
	.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, 
	.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
		box-shadow: inset 1px 0 0 rgba(255,255,255,0.4);
		width: 12px;
		margin: 2px;
		position: absolute;
		height: auto;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}
	
	.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, 
	.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar{ box-shadow: inset 0 1px 0 rgba(255,255,255,0.4); }
	
	.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,  
	.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar, 
	.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
	.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar{ background-color: #555; }
	
	.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, 
	.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar{
		height: 12px;
		width: auto;
	}
	
	.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer{
		background-color: #000; background-color: rgba(0,0,0,0.05);
		box-shadow: inset 1px 1px 16px rgba(0,0,0,0.1);
	}
	
	.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerRail{ background-color: transparent; }
	
	.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonUp{ background-position: -32px -72px; }
	
	.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonDown{ background-position: -32px -92px; }

	.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonLeft{ background-position: -40px -112px; }

	.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonRight{	background-position: -40px -128px; }
	
	
	/* theme: "3d-thick-dark" */
	
	.mCS-3d-thick-dark.mCSB_scrollTools{ box-shadow: inset 0 0 14px rgba(0,0,0,0.2); }
	
	.mCS-3d-thick-dark.mCSB_scrollTools_horizontal{ box-shadow: inset 0 1px 1px rgba(0,0,0,0.1), inset 0 0 14px rgba(0,0,0,0.2); }
	
	.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{ box-shadow: inset 1px 0 0 rgba(255,255,255,0.4), inset -1px 0 0 rgba(0,0,0,0.2); }
	 
	.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar{ box-shadow: inset 0 1px 0 rgba(255,255,255,0.4), inset 0 -1px 0 rgba(0,0,0,0.2); }
	
	.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,  
	.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar, 
	.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
	.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar{ background-color: #777; }
	
	.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer{
		background-color: #fff; background-color: rgba(0,0,0,0.05);
		box-shadow: inset 1px 1px 16px rgba(0,0,0,0.1);
	}
	
	.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerRail{ background-color: transparent; }
	
	.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonUp{ background-position: -112px -72px; }
	
	.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonDown{ background-position: -112px -92px; }

	.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonLeft{ background-position: -120px -112px; }

	.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonRight{	background-position: -120px -128px; }
	
	/* ---------------------------------------- */
	
	
	
	/* theme: "minimal", "minimal-dark" */
	
	.mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical, 
	.mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical{
		right: 0; 
		margin: 12px 0; 
	}
	
	.mCustomScrollBox.mCS-minimal + .mCSB_scrollTools.mCSB_scrollTools_horizontal, 
	.mCustomScrollBox.mCS-minimal + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal, 
	.mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools.mCSB_scrollTools_horizontal, 
	.mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal{
		bottom: 0; 
		margin: 0 12px; 
	}
	
	/* RTL direction/left-side scrollbar */
	.mCS-dir-rtl > .mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical, 
	.mCS-dir-rtl > .mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical{
		left: 0; 
		right: auto;
	}
	
	.mCS-minimal.mCSB_scrollTools .mCSB_draggerRail, 
	.mCS-minimal-dark.mCSB_scrollTools .mCSB_draggerRail{ background-color: transparent; }
	
	.mCS-minimal.mCSB_scrollTools_vertical .mCSB_dragger, 
	.mCS-minimal-dark.mCSB_scrollTools_vertical .mCSB_dragger{ height: 50px; }
	
	.mCS-minimal.mCSB_scrollTools_horizontal .mCSB_dragger, 
	.mCS-minimal-dark.mCSB_scrollTools_horizontal .mCSB_dragger{ width: 50px; }
	
	.mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
		background-color: #fff; background-color: rgba(255,255,255,0.2);
		filter: "alpha(opacity=20)"; -ms-filter: "alpha(opacity=20)"; 
	}
	
	.mCS-minimal.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
	.mCS-minimal.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar{
		background-color: #fff; background-color: rgba(255,255,255,0.5);
		filter: "alpha(opacity=50)"; -ms-filter: "alpha(opacity=50)"; 
	}
	
	
	/* theme: "minimal-dark" */
	
	.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
		background-color: #000; background-color: rgba(0,0,0,0.2);
		filter: "alpha(opacity=20)"; -ms-filter: "alpha(opacity=20)"; 
	}
	
	.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
	.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar{
		background-color: #000; background-color: rgba(0,0,0,0.5);
		filter: "alpha(opacity=50)"; -ms-filter: "alpha(opacity=50)"; 
	}
	
	/* ---------------------------------------- */
	
	
	
	/* theme "light-3", "dark-3" */
	
	.mCS-light-3.mCSB_scrollTools .mCSB_draggerRail, 
	.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail{
		width: 6px;
		background-color: #000; background-color: rgba(0,0,0,0.2);
	}

	.mCS-light-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, 
	.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{ width: 6px; }

	.mCS-light-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, 
	.mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, 
	.mCS-light-3.mCSB_scrollTools_horizontal .mCSB_draggerRail, 
	.mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_draggerRail{
		width: 100%;
		height: 6px;
		margin: 5px 0;
	}
	
	.mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, 
	.mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail, 
	.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, 
	.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail{
		width: 12px;
	}
	
	.mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, 
	.mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail, 
	.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, 
	.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail{
		height: 12px;
		margin: 2px 0;
	}
	
	.mCS-light-3.mCSB_scrollTools .mCSB_buttonUp{ background-position: -32px -72px; }
	
	.mCS-light-3.mCSB_scrollTools .mCSB_buttonDown{ background-position: -32px -92px; }
	
	.mCS-light-3.mCSB_scrollTools .mCSB_buttonLeft{ background-position: -40px -112px; }
	
	.mCS-light-3.mCSB_scrollTools .mCSB_buttonRight{ background-position: -40px -128px; }
	
	
	/* theme "dark-3" */
	
	.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{ background-color: #000; background-color: rgba(0,0,0,0.75); }

	.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar{ background-color: #000; background-color: rgba(0,0,0,0.85); }

	.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
	.mCS-dark-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar{ background-color: #000; background-color: rgba(0,0,0,0.9); }
	
	.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail{ background-color: #000; background-color: rgba(0,0,0,0.1); }
	
	.mCS-dark-3.mCSB_scrollTools .mCSB_buttonUp{ background-position: -112px -72px; }

	.mCS-dark-3.mCSB_scrollTools .mCSB_buttonDown{ background-position: -112px -92px; }

	.mCS-dark-3.mCSB_scrollTools .mCSB_buttonLeft{ background-position: -120px -112px; }

	.mCS-dark-3.mCSB_scrollTools .mCSB_buttonRight{	background-position: -120px -128px; }
	
	/* ---------------------------------------- */
	
	
	
	/* theme "inset", "inset-dark", "inset-2", "inset-2-dark", "inset-3", "inset-3-dark" */
	
	.mCS-inset.mCSB_scrollTools .mCSB_draggerRail, 
	.mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail, 
	.mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail, 
	.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail, 
	.mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail, 
	.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail{
		width: 12px;
		background-color: #000; background-color: rgba(0,0,0,0.2);
	}

	.mCS-inset.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, 
	.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, 
	.mCS-inset-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, 
	.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, 
	.mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, 
	.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{ 
		width: 6px;
		margin: 3px 5px;
		position: absolute;
		height: auto;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}

	.mCS-inset.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, 
	.mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, 
	.mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, 
	.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, 
	.mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, 
	.mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar{
		height: 6px;
		margin: 5px 3px;
		position: absolute;
		width: auto;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}
	
	.mCS-inset.mCSB_scrollTools_horizontal .mCSB_draggerRail, 
	.mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail, 
	.mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_draggerRail, 
	.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail, 
	.mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_draggerRail, 
	.mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail{
		width: 100%;
		height: 12px;
		margin: 2px 0;
	}
	
	.mCS-inset.mCSB_scrollTools .mCSB_buttonUp, 
	.mCS-inset-2.mCSB_scrollTools .mCSB_buttonUp, 
	.mCS-inset-3.mCSB_scrollTools .mCSB_buttonUp{ background-position: -32px -72px; }
	
	.mCS-inset.mCSB_scrollTools .mCSB_buttonDown, 
	.mCS-inset-2.mCSB_scrollTools .mCSB_buttonDown, 
	.mCS-inset-3.mCSB_scrollTools .mCSB_buttonDown{ background-position: -32px -92px; }
	
	.mCS-inset.mCSB_scrollTools .mCSB_buttonLeft, 
	.mCS-inset-2.mCSB_scrollTools .mCSB_buttonLeft, 
	.mCS-inset-3.mCSB_scrollTools .mCSB_buttonLeft{ background-position: -40px -112px; }
	
	.mCS-inset.mCSB_scrollTools .mCSB_buttonRight, 
	.mCS-inset-2.mCSB_scrollTools .mCSB_buttonRight, 
	.mCS-inset-3.mCSB_scrollTools .mCSB_buttonRight{ background-position: -40px -128px; }
	
	
	/* theme "inset-dark", "inset-2-dark", "inset-3-dark" */
	
	.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, 
	.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, 
	.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{ background-color: #000; background-color: rgba(0,0,0,0.75); }

	.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar, 
	.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar, 
	.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar{ background-color: #000; background-color: rgba(0,0,0,0.85); }

	.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
	.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, 
	.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
	.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, 
	.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
	.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar{ background-color: #000; background-color: rgba(0,0,0,0.9); }
	
	.mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail, 
	.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail, 
	.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail{ background-color: #000; background-color: rgba(0,0,0,0.1); }
	
	.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonUp, 
	.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonUp, 
	.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonUp{ background-position: -112px -72px; }

	.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonDown, 
	.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonDown, 
	.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonDown{ background-position: -112px -92px; }

	.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonLeft, 
	.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonLeft, 
	.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonLeft{ background-position: -120px -112px; }

	.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonRight, 
	.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonRight, 
	.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonRight{	background-position: -120px -128px; }
	
	
	/* theme "inset-2", "inset-2-dark" */
	
	.mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail, 
	.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail{
		background-color: transparent;
		border-width: 1px;
		border-style: solid;
		border-color: #fff;
		border-color: rgba(255,255,255,0.2);
		-webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box;
	}
	
	.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail{ border-color: #000; border-color: rgba(0,0,0,0.2); }
	
	
	/* theme "inset-3", "inset-3-dark" */
	
	.mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail{ background-color: #fff; background-color: rgba(255,255,255,0.6); }
	
	.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail{ background-color: #000; background-color: rgba(0,0,0,0.6); }
	
	.mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{ background-color: #000; background-color: rgba(0,0,0,0.75); }
	
	.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar{ background-color: #000; background-color: rgba(0,0,0,0.85); }
	
	.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
	.mCS-inset-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar{ background-color: #000; background-color: rgba(0,0,0,0.9); }
	
	.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{ background-color: #fff; background-color: rgba(255,255,255,0.75); }
	
	.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar{ background-color: #fff; background-color: rgba(255,255,255,0.85); }
	
	.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
	.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar{ background-color: #fff; background-color: rgba(255,255,255,0.9); }
	
    /* ---------------------------------------- */
    
    /*
* ==========================================================
*      DASHBOARD COUNTS
* ==========================================================
*/
.dashboard-counts {
    color: #333;
  }
  
  .dashboard-counts .count-title span {
    font-size: 0.8em;
    color: #aaa;
    display: block;
  }
  
  .dashboard-counts .count-title i {
    font-size: 1.5em;
    color: #379392;
  }
  
  .dashboard-counts .icon {
    margin-right: 10px;
  }
  
  .dashboard-counts strong {
    font-size: 0.9em;
    font-weight: 500;
    color: #555;
    display: block;
  }
  
  .dashboard-counts .count-number {
    font-size: 2em;
    font-weight: 300;
  }
  
  /* DASHBOARD COUNTS MEDIAQUERIES ------------------------- */
  @media (max-width: 991px) {
    .dashboard-counts div[class*='col-'] {
      margin-bottom: 20px;
    }
  }
  
  @media (min-width: 768px) {
    .dashboard-counts strong {
      font-size: 1em;
    }
    .dashboard-counts span {
      font-size: 0.9em;
    }
    .dashboard-counts .count-number {
      font-size: 1.5em;
    }
  }
  
  /*
  * ==========================================================
  *     DASHBOARD HEADER
  * ==========================================================
  */
  .dashboard-header {
    background: #fff;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
  }
  
  .dashboard-header .check-lists {
    margin-top: 20px;
  }
  
  .dashboard-header .check-lists label {
    font-size: 0.75em;
  }
  
  .dashboard-header .pie-chart {
    position: relative;
    width: 300px;
    height: 300px;
    max-width: 100%;
    margin: 0 auto;
  }
  
  .dashboard-header p {
    font-size: 0.75rem;
    font-weight: 400;
    color: #888;
  }
  
  .dashboard-header li {
    margin-bottom: 10px;
  }
  
  /* DASHBOARD HEADER MEDIAQUERIES ------------------------- */
  @media (max-width: 991px) {
    .dashboard-header {
      background: none;
      border: none;
      padding: 0;
    }
    .dashboard-header div[class*='col-'] {
      margin-bottom: 30px;
    }
    .dashboard-header div[class*='col-']:last-of-type {
      margin-bottom: 0;
    }
    .dashboard-header .card {
      background: #fff;
      padding: 30px;
      -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
      box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
      height: 100%;
    }
  }
  
  @media (min-width: 768px) {
    .dashboard-header p {
      font-size: 0.9em;
    }
    .dashboard-header h2 {
      font-size: 1.3rem;
    }
    .dashboard-header .check-lists label {
      font-size: 0.85em;
    }
  }
  
  @media (min-width: 992px) {
    .dashboard-header {
      margin-bottom: 30px;
    }
    .dashboard-header .card {
      -webkit-box-shadow: none;
      box-shadow: none;
      margin-bottom: 0;
    }
  }
  
  /*
  * ==========================================================
  *      STATISTICS
  * ==========================================================
  */
  .statistics {
    /* User Activity ------------- */
  }
  
  .statistics div[class*='col-'] .card {
    padding: 20px;
    margin-bottom: 0;
    height: 100%;
  }
  
  .statistics h2 {
    margin-bottom: 20px;
  }
  
  .statistics p {
    font-size: 0.8em;
    color: #999;
    margin-bottom: 5px;
  }
  
  .statistics .income .icon {
    font-size: 3.5em;
  }
  
  .statistics .income .number {
    font-size: 2.5em;
    color: #555;
  }
  
  .statistics .income strong {
    font-size: 1.5em;
  }
  
  .statistics .data-usage canvas {
    width: 100%;
    max-width: 200px;
    border: none;
  }
  
  .statistics .data-usage strong {
    font-size: 1.5em;
    display: block;
  }
  
  .statistics .data-usage small {
    font-size: 0.85em;
    font-weight: 300;
    color: #aaa;
  }
  
  .statistics .data-usage span {
    font-size: 0.95em;
    color: #aaa;
    display: block;
  }
  
  .statistics .data-usage p {
    margin-top: 10px;
    margin-bottom: 0;
  }
  
  .statistics .user-activity .number {
    font-size: 2.5em;
    font-weight: 300;
    color: #555;
  }
  
  .statistics .user-activity .progress {
    border-radius: 0;
    background: #f5f5f5;
  }
  
  .statistics .user-activity .progress .progress-bar.bg-primary {
    background: #379392 !important;
  }
  
  .statistics .user-activity .page-statistics {
    margin-top: 20px;
  }
  
  .statistics .user-activity .page-statistics strong {
    display: block;
    text-transform: uppercase;
    color: #379392;
    font-size: 2em;
  }
  
  /* STATISTICS  MEDIAQUERIES ------------------------- */
  @media (max-width: 991px) {
    .statistics div[class*='col-'] .card {
      height: auto;
      margin-bottom: 30px;
    }
    .statistics div[class*='col-']:last-of-type .card {
      margin-bottom: 0;
    }
    .statistics .data-usage {
      text-align: center;
    }
    .statistics .data-usage div[class*='col-']:first-of-type {
      padding-bottom: 20px;
    }
    .statistics .data-usage .gmpc {
      padding-bottom: 20px !important;
    }
    p {
      font-size: 0.75em;
    }
  }
  
  @media (min-width: 768px) {
    .statistics h2 {
      font-size: 1.3rem;
    }
  }
  
  /*
  * ==========================================================
  *      UPDATES WIDGETS
  * ==========================================================
  */
  .updates .left-col {
    padding-right: 20px;
  }
  
  .updates .icon {
    margin-right: 10px;
  }
  
  .updates li {
    border-bottom: 1px solid #f3f3f3;
    padding: .75rem 1.25rem;
  }
  
  .updates .update-date {
    font-size: 1.3em;
    font-weight: 700;
    color: #bbb;
    display: block;
  }
  
  .updates span {
    font-size: 0.7em;
    font-weight: 300;
    color: #aaa;
    line-height: 0.5em;
    display: block;
  }
  
  .updates strong {
    font-weight: 400;
    color: #555;
    font-size: 0.9em;
  }
  
  .updates small {
    font-size: 0.75em;
  }
  
  .updates a {
    color: #555;
  }
  
  .updates a:hover, .updates a:focus {
    color: #555;
    text-decoration: none;
  }
  
  .updates h2 {
    margin-bottom: 0;
  }
  
  .updates p {
    font-size: 0.9em;
    color: #999;
    margin-bottom: 5px;
  }
  
  /* UPDATES MEDIAQUERIES ------------------------- */
  @media (max-width: 991px) {
    .updates h2 {
      font-size: 1.05rem;
    }
    .updates strong {
      font-size: 1em;
      font-weight: 400;
    }
    .updates small {
      font-size: 0.85em;
    }
    .updates .full-date {
      font-size: 0.85em;
    }
  }
  
  /*
  * ==========================================================
  *      DAILY FEEDS AND ACTIVITES WIDGETS
  * ==========================================================
  */
  /*  Daily Feeds ------------------- */
  .daily-feeds a.feed-profile {
    margin-right: 10px;
    width: 45px;
    height: 45px;
  }
  
  .daily-feeds a.feed-profile img {
    width: 100%;
    height: 100%;
  }
  
  .daily-feeds .badge {
    margin-right: 10px;
  }
  
  .daily-feeds strong {
    display: inline-block;
    margin-right: 5px;
  }
  
  .daily-feeds small {
    color: #999;
    display: block;
    margin-bottom: 5px;
  }
  
  .daily-feeds .message-card {
    padding: 10px;
    background: #fafafa;
    margin-left: 55px;
    margin-top: 10px;
    margin-bottom: 10px;
    -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  }
  
  .daily-feeds .full-date {
    line-height: 0.7em;
    margin-bottom: 10px;
  }
  
  .daily-feeds .CTAs a {
    margin-right: 5px;
  }
  
  .daily-feeds .CTAs a i {
    margin-right: 5px;
  }
  
  .daily-feeds .CTAs a:last-of-type {
    margin-right: 0;
  }
  
  .daily-feeds a.btn {
    color: #fff;
  }
  
  /* Activities  ------------------- */
  .activities li {
    padding: 0;
  }
  
  .activities .date-holder {
    padding-right: 0;
  }
  
  .activities .date-holder .date {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  
  .activities .date-holder .date span {
    font-size: 0.8em;
  }
  
  .activities .date-holder .icon {
    width: 30px;
    height: 30px;
    line-height: 30px;
    background: #f5f5f5;
    text-align: center;
    margin: 0;
    display: inline-block;
  }
  
  .activities .date-holder span {
    display: block;
    margin: 10px;
  }
  
  .activities .content {
    border-left: 1px solid #eee;
    border-bottom: 20px;
    padding: 10px 20px;
  }
  
  .activities .content strong {
    display: block;
    margin-right: 5px;
  }
  
  /* UPDATES WIDGET MEDIAQUERIES ------------------------- */
  @media (min-width: 768px) {
    .updates strong {
      font-size: 1em;
    }
    .updates small {
      font-size: 0.8em;
    }
  }
  
  /*
  * ==========================================================
  *      PROGRESS CIRCLE
  * ==========================================================
  */
  .gmpc {
    padding: 0 !important;
    width: 100% !important;
    height: auto !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    color: #379392 !important;
    border-right: 1px solid #eee;
  }
  
  @media (max-width: 575px) {
    .gmpc {
      border-right: none;
      border-bottom: 1px solid #eee;
    }
  }
  
  /*
  * ==========================================================
  *       MAIN FOOTER
  * ==========================================================
  */
  footer.main-footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    background: #222;
    padding: 10px 0;
  }
  
  footer.main-footer p {
    font-size: 0.7em;
    color: #777;
    margin: 0;
  }
  
  /* MAIN FOOTER MEDIAQUERIES ------------------------- */
  @media (max-width: 575px) {
    footer.main-footer div[class*="col-"] {
      text-align: center !important;
    }
  }
  
  @media (min-width: 768px) {
    footer.main-footer p {
      font-size: 0.9em;
    }
  }
  
  /*
  * ==========================================================
  *       UTILITIES
  * ==========================================================
  */
  .bg-cover {
    background-size: cover;
  }
  
  /*
  * ==========================================================
  *     FORMS ELEMENTS
  * ==========================================================
  */
  label {
    font-size: .9rem;
  }
  
  .form-control {
    height: calc(2.4rem + 2px);
    padding: 0.45rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    border-radius: 0;
  }
  
  .form-control::-moz-placeholder {
    font-size: 0.75em;
    font-weight: 400;
    color: #aaa;
    font-family: "Roboto", sans-serif;
  }
  
  .form-control::-webkit-input-placeholder {
    font-size: 0.75em;
    font-weight: 400;
    color: #aaa;
    font-family: "Roboto", sans-serif;
  }
  
  .form-control:-ms-input-placeholder {
    font-size: 0.75em;
    font-weight: 400;
    color: #aaa;
    font-family: "Roboto", sans-serif;
  }
  
  .form-control-sm {
    height: calc(1.8125rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
  }
  
  .form-control-lg {
    height: calc(2.875rem + 2px);
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
  }
  
  textarea.form-control {
    height: auto;
  }
  
  select.form-control {
    font-weight: 300;
  }
  
  select.form-control[size], select.form-control[multiple] {
    height: auto;
  }
  
  select.form-control option {
    color: #999;
    font-weight: 300;
  }
  
  .custom-select {
    height: calc(2.4rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
    background-size: 8px 10px;
    border: 1px solid #ced4da;
    border-radius: 0;
  }
  
  .custom-select:focus {
    border-color: #7ccecd;
    outline: 0;
  }
  
  .custom-select-sm {
    height: calc(1.8125rem + 2px);
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    font-size: 75%;
  }
  
  .custom-select-lg {
    height: calc(2.875rem + 2px);
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    font-size: 125%;
  }
  
  .form-group {
    margin-bottom: 10px;
  }
  
  .form-group div[class*='col-'] {
    margin-bottom: 10px;
  }
  
  .input-group-text {
    color: #868e96;
    background: #fff;
    border-radius: 0;
  }
  
  .form-group-material {
    position: relative;
    margin-bottom: 30px;
  }
  
  input.input-material {
    width: 100%;
    border: none;
    border-bottom: 1px solid #eee;
    padding: 10px 0;
    color: #868e96;
    font-weight: 300;
  }
  
  input.input-material.is-valid {
    border-color: #28a745 !important;
  }
  
  input.input-material.is-invalid {
    border-color: #dc3545 !important;
  }
  
  input.input-material:focus {
    border-color: #379392;
    outline: none;
  }
  
  input.input-material ~ label {
    color: #aaa;
    position: absolute;
    top: 14px;
    left: 0;
    cursor: text;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    font-weight: 300;
  }
  
  input.input-material ~ label.active {
    font-size: 0.8rem;
    top: -10px;
    color: #379392;
  }
  
  input.input-material.is-invalid ~ label {
    color: #dc3545;
  }
  
  input.form-control-custom {
    visibility: hidden;
    width: 1px;
  }
  
  input.form-control-custom:checked + label::before {
    background: #379392 !important;
  }
  
  input.form-control-custom:checked + label::after {
    opacity: 1 !important;
  }
  
  input.form-control-custom.radio-custom + label::before {
    border-radius: 50%;
  }
  
  input.form-control-custom.radio-custom + label::after {
    content: '';
    width: 6px;
    height: 6px;
    line-height: 6px;
    background: #fff;
    border-radius: 50%;
    top: 7px;
    left: 5px;
  }
  
  input.form-control-custom[disabled] + label {
    cursor: not-allowed;
  }
  
  input.form-control-custom[disabled] + label::before, input.form-control-custom[disabled] + label::after {
    border: 2px solid #aaa;
    cursor: not-allowed;
  }
  
  input.form-control-custom[checked] + label::before, input.form-control-custom[checked] + label::after {
    border: none;
  }
  
  input.form-control-custom + label {
    font-size: 0.85rem;
    margin-bottom: 0;
    margin-left: 0;
    color: #999;
    padding-left: 25px;
    padding-top: 2px;
    position: relative;
  }
  
  input.form-control-custom + label span {
    margin-left: 20px;
  }
  
  input.form-control-custom + label::before {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    line-height: 16px;
    background: #ddd;
    color: #fff;
    text-align: center;
    position: absolute;
    top: 2px;
    left: 0;
    -webkit-transition: all 0.1s;
    transition: all 0.1s;
    cursor: pointer;
  }
  
  input.form-control-custom + label::after {
    content: '\f00c';
    display: block;
    font-family: 'FontAwesome';
    font-size: 8px;
    color: #fff;
    position: absolute;
    top: 4px;
    left: 4px;
    -webkit-transition: all 0.1s;
    transition: all 0.1s;
    opacity: 0;
    cursor: pointer;
  }
  
  button {
    cursor: pointer;
  }
  
  /*
  * ==========================================================
  *     FORMS GENERAL
  * ==========================================================
  */
  .forms .line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #eee;
    margin: 20px 0 30px;
  }
  
  .forms p {
    font-size: 0.8rem;
    color: #868e96;
  }
  
  .forms small {
    color: #868e96;
  }
  
  /* FORMS MEDIAQUERIES -------------------------- */
  @media (min-width: 768px) {
    .forms h2 {
      font-size: 1rem;
    }
    .forms input.form-control {
      border-radius: 0;
    }
    .forms input.form-control::-moz-placeholder {
      font-size: 0.85em;
    }
    .forms input.form-control::-webkit-input-placeholder {
      font-size: 0.85em;
    }
    .forms input.form-control:-ms-input-placeholder {
      font-size: 0.85em;
    }
    .forms p {
      font-size: 0.9em;
    }
  }
  
  /*
  * ==========================================================
  *     Profile
  * ==========================================================
  */
  .avatar {
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    border-radius: 50%;
    display: inline-block;
    background: #ced4da no-repeat center/cover;
    position: relative;
    text-align: center;
    color: #868e96;
    font-weight: 600;
    vertical-align: bottom;
  }
  
  .avatar.avatar-md {
    width: 3rem;
    height: 3rem;
  }
  
  .avatar.avatar-lg {
    width: 4rem;
    height: 4rem;
  }
  
  .avatar.avatar-xl {
    width: 5rem;
    height: 5rem;
  }
  
  .avatar.avatar-xxl {
    width: 7rem;
    height: 7rem;
    min-width: 7rem;
  }
  
  .card-profile .card-header {
    height: 9rem;
    background-size: cover;
    background-position: center center;
  }
  
  .card-profile-img {
    max-width: 8rem;
    margin-top: -6rem;
    margin-bottom: 1rem;
    border: 3px solid #fff;
    border-radius: 100%;
  }
  
  .card-status {
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    height: 3px;
    background: rgba(0, 40, 100, 0.12);
  }
  
  .card-category {
    text-transform: uppercase;
    font-weight: bold;
    color: #868e96;
    font-size: 0.9rem;
  }
  
  /*
  * ==========================================================
  *     NoUI Slider
  * ==========================================================
  */
  .nouislider {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  
  .noUi-target {
    background: #fff;
    border-radius: 0;
    border: 1px solid #dee2e6;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  
  .noUi-handle {
    background: #dee2e6;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
    border-radius: 0;
  }
  
  .noUi-handle:focus {
    outline: none;
  }
  
  .noUi-handle:before, .noUi-handle:after {
    background: #adb5bd;
  }
  
  .noUi-tooltip {
    border-radius: 0;
    background: #f8f9fa;
    border-color: transparent;
    color: #212529;
    font-size: .85rem;
    font-weight: bold;
  }
  
  .noUi-connect {
    background: #379392;
  }
  
  /*
  * ==========================================================
  *    Datepicker
  * ==========================================================
  */
  .datepicker-dropdown.dropdown-menu {
    width: 300px;
    margin-top: 0;
  }
  
  .datepicker-dropdown.dropdown-menu table {
    width: 100%;
  }
  
  .datepicker-dropdown.dropdown-menu td {
    width: 35px;
    height: 35px;
    border-radius: 0;
  }
  
  .datepicker-dropdown.dropdown-menu td.active, .datepicker-dropdown.dropdown-menu td:active {
    background-color: #379392 !important;
    border-color: #379392 !important;
  }
  
  .datepicker-dropdown.dropdown-menu:before, .datepicker-dropdown.dropdown-menu:after {
    display: none;
  }
  
  .datepicker-days {
    width: 300px;
  }
  
  .bootstrap-touchspin {
    max-width: 15rem;
  }
  
  /*
  * ==========================================================
  *     Bootstrap Select
  * ==========================================================
  */
  .bootstrap-select .dropdown-menu li a {
    padding: 0.5rem 1.5rem;
    font-weight: 400;
    color: #212529;
    background: none;
    font-size: 0.95rem;
  }
  
  .bootstrap-select .dropdown-menu li a:hover {
    background: #fff;
    color: #379392;
  }
  
  .bootstrap-select .dropdown-toggle:focus {
    outline: none !important;
  }
  
  .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: 300px;
  }
  
  /*
  * ==========================================================
  *     Bootstrap Tags Input
  * ==========================================================
  */
  .bootstrap-tagsinput .label-info {
    background-color: #379392;
    display: inline-block;
    padding: 2px 10px;
    border-radius: 3px;
    font-size: 14px;
    margin: 2px;
  }
  
  .bootstrap-tagsinput {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
    padding: 3px 7px;
    border: 1px solid #dee2e6;
    color: #ffffff;
    width: 100%;
  }
  
  .bootstrap-tagsinput .tag [data-role="remove"]:hover {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  
  /*
  * ==========================================================
  *     Data Table
  * ==========================================================
  */
  .block .dataTables_wrapper {
    padding-left: 0;
    padding-right: 0;
  }
  
  .dataTables_filter select:focus,
  .dataTables_filter input:focus,
  .dataTables_length select:focus,
  .dataTables_length input:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  
  /*
  * ==========================================================
  *     Multiselect
  * ==========================================================
  */
  .ms-container {
    background: transparent url("./multiselect-switch.png") no-repeat 50% 50%;
    width: 370px;
  }
  
  .ms-container:after {
    content: ".";
    display: block;
    height: 0;
    line-height: 0;
    font-size: 0;
    clear: both;
    min-height: 0;
    visibility: hidden;
  }
  
  .ms-container .ms-selectable,
  .ms-container .ms-selection {
    color: #555555;
    float: left;
    width: 45%;
  }
  
  .ms-container .ms-selection {
    float: right;
  }
  
  .ms-container .ms-list {
    -webkit-transition: border linear 0.2s;
    transition: border linear 0.2s;
    border: 1px solid #e6e6e6;
    border-radius: 0;
    position: relative;
    height: 200px;
    padding: 0;
    overflow-y: auto;
  }
  
  .ms-container .ms-list.ms-focus {
    border-color: #379392;
  }
  
  .ms-container ul {
    margin: 0;
    list-style-type: none;
    padding: 0;
  }
  
  .ms-container .ms-optgroup-container {
    width: 100%;
  }
  
  .ms-container .ms-optgroup-label {
    margin: 0;
    padding: 5px 0px 0px 5px;
    cursor: pointer;
    color: #999;
  }
  
  .ms-container .ms-selectable li.ms-elem-selectable,
  .ms-container .ms-selection li.ms-elem-selection {
    padding: 5px 10px;
    color: #212529;
    font-size: 14px;
  }
  
  .ms-container .ms-selectable li.ms-hover,
  .ms-container .ms-selection li.ms-hover {
    cursor: pointer;
    color: #fff;
    text-decoration: none;
    background-color: #379392;
  }
  
  .ms-container .ms-selectable li.disabled,
  .ms-container .ms-selection li.disabled {
    color: #aaa;
    cursor: text;
  }
  
  /*
  * ==========================================================
  *     Typeahead
  * ==========================================================
  */
  .form-group-typeahead {
    position: relative;
  }
  
  /*
  * ==========================================================
  *      Summernote
  * ==========================================================
  */
  .note-editor.card {
    margin-bottom: 0;
  }
  
  .note-editor .dropdown-toggle::after {
    margin-left: .6rem;
    margin-right: .4rem;
  }
  
  .note-editor.note-frame {
    border: 1px solid #dee2e6;
    border-radius: 0;
  }
  
  .note-editor.note-frame .note-editing-area .note-editable {
    padding: 10px;
    overflow: auto;
    color: #212529;
    background-color: #fff;
  }
  
  .note-editor.note-frame .note-statusbar {
    border-radius: 0;
  }
  
  .note-editor .note-toolbar {
    background-color: #e9ecef;
    border-bottom: 1px solid #ced4da;
    margin: 0;
  }
  
  .note-editor .btn-group-sm > .btn,
  .note-editor .btn-sm {
    padding: 8px 12px;
  }
  
  .note-editor .btn-light.disabled {
    color: #343a40;
  }
  
  .note-editor .btn-light.active, .note-editor .btn-light:active {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  
  .note-editor .show > .btn-light.dropdown-toggle {
    background-color: transparent;
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  
  .note-editor .card-header.note-toolbar .note-color .dropdown-toggle {
    width: auto !important;
  }
  
  .note-editor i[class*='icon-'] {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  
  .note-editor .note-statusbar {
    background-color: #e9ecef !important;
  }
  
  .note-editor .dropdown-style p,
  .note-editor .dropdown-style pre,
  .note-editor .dropdown-style blockquote {
    margin-bottom: 0;
  }
  
  /*
  * ==========================================================
  *      Dropzone
  * ==========================================================
  */
  @-webkit-keyframes passing-through {
    0% {
      opacity: 0;
      -webkit-transform: translateY(40px);
      transform: translateY(40px);
    }
    30%, 70% {
      opacity: 1;
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
    }
    100% {
      opacity: 0;
      -webkit-transform: translateY(-40px);
      transform: translateY(-40px);
    }
  }
  @keyframes passing-through {
    0% {
      opacity: 0;
      -webkit-transform: translateY(40px);
      transform: translateY(40px);
    }
    30%, 70% {
      opacity: 1;
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
    }
    100% {
      opacity: 0;
      -webkit-transform: translateY(-40px);
      transform: translateY(-40px);
    }
  }
  
  @-webkit-keyframes slide-in {
    0% {
      opacity: 0;
      -webkit-transform: translateY(40px);
      transform: translateY(40px);
    }
    30% {
      opacity: 1;
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
    }
  }
  
  @keyframes slide-in {
    0% {
      opacity: 0;
      -webkit-transform: translateY(40px);
      transform: translateY(40px);
    }
    30% {
      opacity: 1;
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
    }
  }
  
  @-webkit-keyframes pulse {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    10% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
    20% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  
  @keyframes pulse {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    10% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
    20% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  
  .dropzone {
    min-height: 150px;
    border: 2px dashed #dee2e6;
    background: white;
    padding: 20px 20px;
  }
  
  .dropzone.dz-clickable {
    cursor: pointer;
  }
  
  .dropzone.dz-clickable * {
    cursor: default;
  }
  
  .dropzone.dz-clickable .dz-message {
    cursor: pointer;
  }
  
  .dropzone.dz-clickable .dz-message * {
    cursor: pointer;
  }
  
  .dropzone.dz-started .dz-message {
    display: none;
  }
  
  .dropzone.dz-drag-hover {
    border-style: solid;
  }
  
  .dropzone.dz-drag-hover .dz-message {
    opacity: 0.5;
  }
  
  .dropzone .dz-message {
    text-align: center;
    margin: 2em 0;
  }
  
  .dropzone .dz-preview {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: 16px;
    min-height: 100px;
  }
  
  .dropzone .dz-preview:hover {
    z-index: 1000;
  }
  
  .dropzone .dz-preview:hover .dz-details {
    opacity: 1;
  }
  
  .dropzone .dz-preview.dz-file-preview .dz-image {
    background: #999;
    background: -webkit-gradient(linear, left top, left bottom, from(#eee), to(#ddd));
    background: linear-gradient(to bottom, #eee, #ddd);
  }
  
  .dropzone .dz-preview.dz-file-preview .dz-details {
    opacity: 1;
  }
  
  .dropzone .dz-preview.dz-image-preview {
    background: white;
  }
  
  .dropzone .dz-preview.dz-image-preview .dz-details {
    -webkit-transition: opacity 0.2s linear;
    transition: opacity 0.2s linear;
  }
  
  .dropzone .dz-preview .dz-remove {
    font-size: 14px;
    text-align: center;
    display: block;
    cursor: pointer;
    border: none;
  }
  
  .dropzone .dz-preview .dz-remove:hover {
    text-decoration: underline;
  }
  
  .dropzone .dz-preview:hover .dz-details {
    opacity: 1;
  }
  
  .dropzone .dz-preview .dz-details {
    z-index: 20;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    font-size: 13px;
    min-width: 100%;
    max-width: 100%;
    padding: 2em 1em;
    text-align: center;
    color: rgba(0, 0, 0, 0.9);
    line-height: 150%;
  }
  
  .dropzone .dz-preview .dz-details .dz-size {
    margin-bottom: 1em;
    font-size: 16px;
  }
  
  .dropzone .dz-preview .dz-details .dz-filename {
    white-space: nowrap;
  }
  
  .dropzone .dz-preview .dz-details .dz-filename:hover span {
    border: 1px solid rgba(200, 200, 200, 0.8);
    background-color: rgba(255, 255, 255, 0.8);
  }
  
  .dropzone .dz-preview .dz-details .dz-filename:not(:hover) {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .dropzone .dz-preview .dz-details .dz-filename:not(:hover) span {
    border: 1px solid transparent;
  }
  
  .dropzone .dz-preview .dz-details .dz-filename span {
    background-color: rgba(255, 255, 255, 0.4);
    padding: 0 0.4em;
    border-radius: 3px;
  }
  
  .dropzone .dz-preview .dz-details .dz-size span {
    background-color: rgba(255, 255, 255, 0.4);
    padding: 0 0.4em;
    border-radius: 3px;
  }
  
  .dropzone .dz-preview:hover .dz-image img {
    -webkit-transform: scale(1.05, 1.05);
    transform: scale(1.05, 1.05);
    -webkit-filter: blur(8px);
    filter: blur(8px);
  }
  
  .dropzone .dz-preview .dz-image {
    overflow: hidden;
    width: 120px;
    height: 120px;
    position: relative;
    display: block;
    z-index: 10;
  }
  
  .dropzone .dz-preview .dz-image img {
    display: block;
  }
  
  .dropzone .dz-preview.dz-success .dz-success-mark {
    -webkit-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
    animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  }
  
  .dropzone .dz-preview.dz-error .dz-error-mark {
    opacity: 1;
    -webkit-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
    animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  }
  
  .dropzone .dz-preview .dz-success-mark, .dropzone .dz-preview .dz-error-mark {
    pointer-events: none;
    opacity: 0;
    z-index: 500;
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    margin-left: -27px;
    margin-top: -27px;
  }
  
  .dropzone .dz-preview .dz-success-mark svg, .dropzone .dz-preview .dz-error-mark svg {
    display: block;
    width: 54px;
    height: 54px;
  }
  
  .dropzone .dz-preview.dz-processing .dz-progress {
    opacity: 1;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
  }
  
  .dropzone .dz-preview.dz-complete .dz-progress {
    opacity: 0;
    -webkit-transition: opacity 0.4s ease-in;
    transition: opacity 0.4s ease-in;
  }
  
  .dropzone .dz-preview:not(.dz-processing) .dz-progress {
    -webkit-animation: pulse 6s ease infinite;
    animation: pulse 6s ease infinite;
  }
  
  .dropzone .dz-preview .dz-progress {
    opacity: 1;
    z-index: 1000;
    pointer-events: none;
    position: absolute;
    height: 16px;
    left: 50%;
    top: 50%;
    margin-top: -8px;
    width: 80px;
    margin-left: -40px;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 8px;
    overflow: hidden;
  }
  
  .dropzone .dz-preview .dz-progress .dz-upload {
    background: #333;
    background: -webkit-gradient(linear, left top, left bottom, from(#666), to(#444));
    background: linear-gradient(to bottom, #666, #444);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 0;
    -webkit-transition: width 300ms ease-in-out;
    transition: width 300ms ease-in-out;
  }
  
  .dropzone .dz-preview.dz-error .dz-error-message {
    display: block;
  }
  
  .dropzone .dz-preview.dz-error:hover .dz-error-message {
    opacity: 1;
    pointer-events: auto;
  }
  
  .dropzone .dz-preview .dz-error-message {
    pointer-events: none;
    z-index: 1000;
    position: absolute;
    display: block;
    display: none;
    opacity: 0;
    -webkit-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
    border-radius: 3px;
    font-size: 13px;
    top: 130px;
    left: -10px;
    width: 140px;
    background: #be2626;
    background: -webkit-gradient(linear, left top, left bottom, from(#be2626), to(#a92222));
    background: linear-gradient(to bottom, #be2626, #a92222);
    padding: 0.5em 1.2em;
    color: white;
  }
  
  .dropzone .dz-preview .dz-error-message:after {
    content: '';
    position: absolute;
    top: -6px;
    left: 64px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #be2626;
  }
  
  /*
  * ==========================================================
  *     LOGIN PAGE
  * ==========================================================
  */
  .login-page,
  .register-page {
    width: 100%;
    background: #f5f5f5;
    min-height: auto;
    padding-bottom: 0;
  }
  
  .login-page .form-outer,
  .register-page .form-outer {
    min-height: 100vh;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px 0;
    position: relative;
  }
  
  .login-page .copyrights,
  .register-page .copyrights {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
  }
  
  .login-page .copyrights p,
  .register-page .copyrights p {
    font-size: 0.6em;
    font-weight: 300;
    margin-bottom: 10px;
  }
  
  .login-page .form-inner,
  .register-page .form-inner {
    padding: 40px 30px;
    background: #fff;
    -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  .login-page .logo,
  .register-page .logo {
    font-size: 2em;
    font-weight: 500;
  }
  
  .login-page .logo span,
  .register-page .logo span {
    color: #ccc;
  }
  
  .login-page p,
  .register-page p {
    font-size: 0.8em;
    font-weight: 300;
    color: #aaa;
    margin-bottom: 0;
  }
  
  .login-page form,
  .register-page form {
    margin: 2rem auto 1rem;
    max-width: 300px;
  }
  
  .login-page a.forgot-pass,
  .login-page a.signup,
  .register-page a.forgot-pass,
  .register-page a.signup {
    font-size: 0.65em;
    color: #66b0ff;
  }
  
  .login-page a.forgot-pass:hover, .login-page a.forgot-pass:focus,
  .login-page a.signup:hover,
  .login-page a.signup:focus,
  .register-page a.forgot-pass:hover,
  .register-page a.forgot-pass:focus,
  .register-page a.signup:hover,
  .register-page a.signup:focus {
    color: #66b0ff;
    text-decoration: none;
  }
  
  .login-page a.forgot-pass,
  .register-page a.forgot-pass {
    display: block;
  }
  
  .login-page a.create-account,
  .register-page a.create-account {
    border-radius: 50px;
    font-size: 0.75em;
    margin-top: 10px;
    color: #999;
    text-transform: uppercase;
  }
  
  .login-page .terms-conditions,
  .register-page .terms-conditions {
    margin-top: 20px;
  }
  
  .login-page small,
  .register-page small {
    font-size: 0.65em;
    color: #aaa;
    line-height: 0.9em;
  }
  
  /* LOGIN MEDIAQUERIES -------------------------- */
  @media (min-width: 768px) {
    .login-page p,
    .register-page p {
      font-size: 0.9em;
    }
    .login-page form label.label-custom,
    .register-page form label.label-custom {
      font-size: 0.9em;
    }
    .login-page form small,
    .register-page form small {
      font-size: 0.8em;
    }
    .login-page .terms-conditions label,
    .register-page .terms-conditions label {
      font-size: 0.9em;
    }
    .login-page a.forgot-pass,
    .login-page a.signup,
    .register-page a.forgot-pass,
    .register-page a.signup {
      font-size: 0.8em;
    }
    .login-page small,
    .register-page small {
      font-size: 0.8em;
    }
    .login-page .copyrights p,
    .register-page .copyrights p {
      font-size: 0.85em;
      font-weight: 400;
    }
  }
  
  /*
  * ==========================================================
  *     POLAR CHART
  * ==========================================================
  */
  .polar-chart-example .chart-container {
    width: 500px;
    max-width: 100%;
    margin: 20px auto;
  }
  
  /*
  * ==========================================================
  *     PIE CHART
  * ==========================================================
  */
  .pie-chart-example .chart-container {
    width: 500px;
    max-width: 100%;
    margin: 20px auto;
  }
  
  /*
  * ==========================================================
  *     RADAR CHART
  * ==========================================================
  */
  .radar-chart-example .chart-container {
    width: 500px;
    max-width: 100%;
    margin: 20px auto;
  }
  
  /* CHARTS MEDIAQUERIES -------------------------- */
  @media (min-width: 768px) {
    section.charts h2 {
      font-size: 1rem;
    }
  }
  
  /*
  * ==========================================================
  *     CHARTS - PREMIUM
  * ==========================================================
  */
  .gauge-container {
    position: relative;
  }
  
  .gauge-canvas {
    display: block;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
  
  .gauge-label {
    display: inline-block;
    position: absolute;
    left: 50%;
    bottom: 50px;
    font-size: 1.2rem;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    font-weight: bold;
  }
  
  .sparklines {
    display: inline-block;
    margin-left: 20px;
  }
  
  /*
  * ==========================================================
  *     TABLES
  * ==========================================================
  */
  .table {
    margin-bottom: 0;
  }
  
  .table td,
  .table th {
    border-color: #dee2e6;
  }
  
  .table thead th {
    border: none;
  }
  
  .table-striped tbody tr {
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  
  .table-bordered td,
  .table-bordered th {
    border-color: #dee2e6;
  }
  
  /*
  * ==========================================================
  *    Data Table
  * ==========================================================
  */
  .dataTables_wrapper.container-fluid {
    padding: 10px 5px 0 0;
  }
  
  div.dataTables_wrapper div.dataTables_info {
    padding-top: 1.5rem;
  }
  
  div.dataTables_wrapper div.dataTables_paginate {
    padding-top: 0.65rem;
  }
  
  /*
  * ==========================================================
  *     COMPONENTS
  * ==========================================================
  */
  /*
  * ==========================================================
  *     HUBSPOT MESSENGER
  * ==========================================================
  */
  ul.messenger {
    margin: 0;
    padding: 0;
  }
  
  ul.messenger > li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  ul.messenger.messenger-empty {
    display: none;
  }
  
  ul.messenger .messenger-message {
    overflow: hidden;
    *zoom: 1;
  }
  
  ul.messenger .messenger-message.messenger-hidden {
    display: none;
  }
  
  ul.messenger .messenger-message .messenger-phrase {
    padding-right: 5px;
  }
  
  ul.messenger .messenger-message .messenger-actions {
    float: right;
  }
  
  ul.messenger .messenger-message .messenger-actions a {
    padding-right: 5px;
    cursor: pointer;
    text-decoration: underline;
  }
  
  ul.messenger .messenger-message ul, ul.messenger .messenger-message ol {
    margin: 10px 18px 0;
  }
  
  ul.messenger.messenger-fixed {
    position: fixed;
    z-index: 10000;
  }
  
  ul.messenger.messenger-fixed .messenger-message {
    min-width: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  ul.messenger.messenger-fixed .message .messenger-actions {
    float: left;
  }
  
  ul.messenger.messenger-fixed.messenger-on-top {
    top: 20px;
  }
  
  ul.messenger.messenger-fixed.messenger-on-bottom {
    bottom: 20px;
  }
  
  ul.messenger.messenger-fixed.messenger-on-top, ul.messenger.messenger-fixed.messenger-on-bottom {
    left: 50%;
    width: 800px;
    margin-left: -400px;
  }
  
  ul.messenger.messenger-fixed.messenger-on-top.messenger-on-right, ul.messenger.messenger-fixed.messenger-on-bottom.messenger-on-right {
    right: 20px;
    left: auto;
  }
  
  ul.messenger.messenger-fixed.messenger-on-top.messenger-on-left, ul.messenger.messenger-fixed.messenger-on-bottom.messenger-on-left {
    left: 20px;
    margin-left: 0px;
  }
  
  ul.messenger.messenger-fixed.messenger-on-right, ul.messenger.messenger-fixed.messenger-on-left {
    width: 350px;
  }
  
  ul.messenger.messenger-fixed.messenger-on-right .messenger-actions, ul.messenger.messenger-fixed.messenger-on-left .messenger-actions {
    float: left;
  }
  
  ul.messenger .messenger-spinner {
    display: none;
  }
  
  ul.messenger .messenger-clickable {
    cursor: pointer;
  }
  
  @-webkit-keyframes ui-spinner-rotate-right {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    25% {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }
    50% {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }
    75% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @keyframes ui-spinner-rotate-right {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    25% {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }
    50% {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }
    75% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @-webkit-keyframes ui-spinner-rotate-left {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    25% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    50% {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }
    75% {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @keyframes ui-spinner-rotate-left {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    25% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    50% {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }
    75% {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  .messenger-spinner {
    position: relative;
    border-radius: 100%;
  }
  
  ul.messenger.messenger-spinner-active .messenger-spinner .messenger-spinner {
    display: block;
  }
  
  .messenger-spinner .messenger-spinner-side {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
  }
  
  .messenger-spinner .messenger-spinner-side .messenger-spinner-fill {
    border-radius: 999px;
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }
  
  .messenger-spinner .messenger-spinner-side-left {
    left: 0;
  }
  
  .messenger-spinner .messenger-spinner-side-left .messenger-spinner-fill {
    left: 100%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    -webkit-animation-name: ui-spinner-rotate-left;
    animation-name: ui-spinner-rotate-left;
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
  }
  
  .messenger-spinner .messenger-spinner-side-right {
    left: 50%;
  }
  
  .messenger-spinner .messenger-spinner-side-right .messenger-spinner-fill {
    left: -100%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    -webkit-animation-name: ui-spinner-rotate-right;
    animation-name: ui-spinner-rotate-right;
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
  }
  
  ul.messenger-theme-flat {
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: #212529;
  }
  
  ul.messenger-theme-flat.messenger-empty {
    display: none;
  }
  
  ul.messenger-theme-flat .messenger-message {
    -webkit-box-shadow: inset 0px 1px rgba(255, 255, 255, 0.13), inset 48px 0px 0px #292929;
    box-shadow: inset 0px 1px rgba(255, 255, 255, 0.13), inset 48px 0px 0px #292929;
    border-radius: 0px;
    position: relative;
    border: 0px;
    margin-bottom: 0px;
    font-size: 13px;
    background: transparent;
    color: #f0f0f0;
    font-weight: 500;
    padding: 10px 30px 13px 65px;
  }
  
  ul.messenger-theme-flat .messenger-message .messenger-close {
    position: absolute;
    top: 0px;
    right: 0px;
    color: #888;
    opacity: 1;
    font-weight: bold;
    display: block;
    font-size: 20px;
    line-height: 20px;
    padding: 8px 10px 7px 7px;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
  }
  
  ul.messenger-theme-flat .messenger-message .messenger-close:hover {
    color: #bbb;
  }
  
  ul.messenger-theme-flat .messenger-message .messenger-close:active {
    color: #777;
  }
  
  ul.messenger-theme-flat .messenger-message .messenger-actions {
    float: none;
    margin-top: 10px;
  }
  
  ul.messenger-theme-flat .messenger-message .messenger-actions a {
    border-radius: 4px;
    text-decoration: none;
    color: #aaa;
    background: #2e2e2e;
    display: inline-block;
    padding: 10px;
    margin-right: 10px;
    padding: 4px 11px 6px;
    text-transform: capitalize;
  }
  
  ul.messenger-theme-flat .messenger-message .messenger-actions a:hover {
    color: #f0f0f0;
    background: #2e2e2e;
  }
  
  ul.messenger-theme-flat .messenger-message .messenger-actions a:active {
    background: #292929;
    color: #aaa;
  }
  
  ul.messenger-theme-flat .messenger-message .messenger-actions .messenger-phrase {
    display: none;
  }
  
  ul.messenger-theme-flat .messenger-message .messenger-message-inner:before {
    border-radius: 50%;
    position: absolute;
    left: 17px;
    display: block;
    content: " ";
    top: 50%;
    margin-top: -8px;
    height: 13px;
    width: 13px;
    z-index: 20;
  }
  
  ul.messenger-theme-flat .messenger-message.alert-success .messenger-message-inner:before {
    background: #28a745;
  }
  
  ul.messenger-theme-flat .messenger-message.alert-info .messenger-message-inner:before {
    background: #17a2b8;
  }
  
  ul.messenger-theme-flat .messenger-message.alert-error .messenger-message-inner:before {
    background: #dc3545;
  }
  
  ul.messenger-theme-flat .messenger-message.alert-error.messenger-retry-soon .messenger-spinner {
    width: 32px;
    height: 32px;
    background: transparent;
  }
  
  ul.messenger-theme-flat .messenger-message.alert-error.messenger-retry-soon .messenger-spinner .messenger-spinner-side .messenger-spinner-fill {
    background: #dc3545;
    -webkit-animation-duration: 20s;
    animation-duration: 20s;
    opacity: 1;
  }
  
  ul.messenger-theme-flat .messenger-message.alert-error.messenger-retry-soon .messenger-spinner:after {
    content: "";
    background: #292929;
    position: absolute;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    top: 3px;
    left: 3px;
    display: block;
  }
  
  ul.messenger-theme-flat .messenger-message.alert-error.messenger-retry-later .messenger-spinner {
    width: 32px;
    height: 32px;
    background: transparent;
  }
  
  ul.messenger-theme-flat .messenger-message.alert-error.messenger-retry-later .messenger-spinner .messenger-spinner-side .messenger-spinner-fill {
    background: #dc3545;
    -webkit-animation-duration: 600s;
    animation-duration: 600s;
    opacity: 1;
  }
  
  ul.messenger-theme-flat .messenger-message.alert-error.messenger-retry-later .messenger-spinner:after {
    content: "";
    background: #292929;
    position: absolute;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    top: 3px;
    left: 3px;
    display: block;
  }
  
  ul.messenger-theme-flat .messenger-message-slot.messenger-last .messenger-message {
    border-radius: 4px 4px 0px 0px;
    -webkit-box-shadow: inset 48px 0px 0px #292929;
    box-shadow: inset 48px 0px 0px #292929;
  }
  
  ul.messenger-theme-flat .messenger-message-slot.messenger-first .messenger-message {
    border-radius: 0px 0px 4px 4px;
    -webkit-box-shadow: inset 0px 1px rgba(255, 255, 255, 0.13), inset 48px 0px 0px #292929;
    box-shadow: inset 0px 1px rgba(255, 255, 255, 0.13), inset 48px 0px 0px #292929;
  }
  
  ul.messenger-theme-flat .messenger-message-slot.messenger-first.messenger-last .messenger-message {
    border-radius: 4px;
    -webkit-box-shadow: inset 48px 0px 0px #292929;
    box-shadow: inset 48px 0px 0px #292929;
  }
  
  ul.messenger-theme-flat .messenger-spinner {
    display: block;
    position: absolute;
    left: 7px;
    top: 50%;
    margin-top: -18px;
    z-index: 999;
    height: 32px;
    width: 32px;
    z-index: 10;
  }
  
  /*
  * ==========================================================
  *     FULL CALENDAR
  * ==========================================================
  */
  .fc {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  
  .fc h2 {
    font-size: 1.2rem;
  }
  
  .fc-toolbar .fc-center {
    padding-top: .25rem;
  }
  
  .fc-event,
  .fc-event-dot {
    background-color: #379392;
    /* default BACKGROUND color */
    color: #fff !important;
    border-color: #379392;
  }
  
  .fc .alert-info {
    background-color: #69c7c6;
  }
  
  .fc .panel {
    padding: 20px;
    background: #fff;
  }
  
  /*
  * ==========================================================
  *     PRELOADERS
  * ==========================================================
  */
  .spinner-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 999999;
  }
  
  .spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -20px !important;
    margin-top: -20px !important;
  }
  
  /*
  * ==========================================================
  *     GOOGLE MAPS 
  * ==========================================================
  */
  .map {
    width: 100%;
  }
  
  .info-window {
    padding: 0;
  }
  
  .infobox {
    font-family: "Roboto", sans-serif;
    width: 800px;
    background: #fff;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
  }
  
  @media (max-width: 991px) {
    .infobox {
      max-width: 600px;
    }
  }
  
  @media (max-width: 767px) {
    .infobox {
      max-width: 350px;
    }
  }
  
  .infobox .about {
    color: #dee2e6;
    font-size: 0.875rem;
  }
  
  .infobox .details {
    font-size: 0.875rem;
  }
  
  .infobox .address {
    font-size: 0.875rem;
  }
  
  .infobox i.fa {
    margin-right: 5px;
  }
  
  .infobox .text {
    display: table-cell;
    width: 450px;
    padding: 40px 20px 20px 40px;
    vertical-align: top;
  }
  
  .infobox h3 {
    margin-top: 0;
  }
  
  .infobox h3 a {
    color: #495057;
  }
  
  .infobox h3 a:focus, .infobox h3 a:hover {
    color: #379392;
  }
  
  .infobox .image {
    display: table-cell;
    position: relative;
    width: 350px;
    height: 100%;
    background-size: cover;
    background-position-x: center;
    background-position-y: center;
  }
  
  @media (max-width: 767px) {
    .infobox .image {
      display: none;
    }
  }
  
  /*
  * ==========================================================
  *     SPINKIT PRELOADERS
  * ==========================================================
  */
  /*
   *  Usage:
   *
        <div class="sk-rotating-plane"></div>
   *
   */
  .sk-rotating-plane {
    width: 40px;
    height: 40px;
    background-color: #379392;
    margin: 0;
    -webkit-animation: sk-rotatePlane 1.2s infinite ease-in-out;
    animation: sk-rotatePlane 1.2s infinite ease-in-out;
  }
  
  @-webkit-keyframes sk-rotatePlane {
    0% {
      -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
      transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }
    50% {
      -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
      transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }
    100% {
      -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
      transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
  }
  
  @keyframes sk-rotatePlane {
    0% {
      -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
      transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }
    50% {
      -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
      transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }
    100% {
      -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
      transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
  }
  
  /*
   *  Usage:
   *
        <div class="sk-double-bounce">
          <div class="sk-child sk-double-bounce1"></div>
          <div class="sk-child sk-double-bounce2"></div>
        </div>
   *
   */
  .sk-double-bounce {
    width: 40px;
    height: 40px;
    position: relative;
    margin: 0;
  }
  
  .sk-double-bounce .sk-child {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #379392;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: sk-doubleBounce 2.0s infinite ease-in-out;
    animation: sk-doubleBounce 2.0s infinite ease-in-out;
  }
  
  .sk-double-bounce .sk-double-bounce2 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
  }
  
  @-webkit-keyframes sk-doubleBounce {
    0%, 100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    50% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  
  @keyframes sk-doubleBounce {
    0%, 100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    50% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  
  /*
   *  Usage:
   *
        <div class="sk-wave">
          <div class="sk-rect sk-rect1"></div>
          <div class="sk-rect sk-rect2"></div>
          <div class="sk-rect sk-rect3"></div>
          <div class="sk-rect sk-rect4"></div>
          <div class="sk-rect sk-rect5"></div>
        </div>
   *
   */
  .sk-wave {
    margin: 0;
    width: 50px;
    height: 40px;
    text-align: center;
    font-size: 10px;
  }
  
  .sk-wave .sk-rect {
    background-color: #379392;
    height: 100%;
    width: 6px;
    display: inline-block;
    -webkit-animation: sk-waveStretchDelay 1.2s infinite ease-in-out;
    animation: sk-waveStretchDelay 1.2s infinite ease-in-out;
  }
  
  .sk-wave .sk-rect1 {
    -webkit-animation-delay: -1.2s;
    animation-delay: -1.2s;
  }
  
  .sk-wave .sk-rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }
  
  .sk-wave .sk-rect3 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
  
  .sk-wave .sk-rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }
  
  .sk-wave .sk-rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }
  
  @-webkit-keyframes sk-waveStretchDelay {
    0%, 40%, 100% {
      -webkit-transform: scaleY(0.4);
      transform: scaleY(0.4);
    }
    20% {
      -webkit-transform: scaleY(1);
      transform: scaleY(1);
    }
  }
  
  @keyframes sk-waveStretchDelay {
    0%, 40%, 100% {
      -webkit-transform: scaleY(0.4);
      transform: scaleY(0.4);
    }
    20% {
      -webkit-transform: scaleY(1);
      transform: scaleY(1);
    }
  }
  
  /*
   *  Usage:
   *
        <div class="sk-wandering-cubes">
          <div class="sk-cube sk-cube1"></div>
          <div class="sk-cube sk-cube2"></div>
        </div>
   *
   */
  .sk-wandering-cubes {
    margin: 0;
    width: 40px;
    height: 40px;
    position: relative;
  }
  
  .sk-wandering-cubes .sk-cube {
    background-color: #379392;
    width: 10px;
    height: 10px;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: sk-wanderingCube 1.8s ease-in-out -1.8s infinite both;
    animation: sk-wanderingCube 1.8s ease-in-out -1.8s infinite both;
  }
  
  .sk-wandering-cubes .sk-cube2 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }
  
  @-webkit-keyframes sk-wanderingCube {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    25% {
      -webkit-transform: translateX(30px) rotate(-90deg) scale(0.5);
      transform: translateX(30px) rotate(-90deg) scale(0.5);
    }
    50% {
      /* Hack to make FF rotate in the right direction */
      -webkit-transform: translateX(30px) translateY(30px) rotate(-179deg);
      transform: translateX(30px) translateY(30px) rotate(-179deg);
    }
    50.1% {
      -webkit-transform: translateX(30px) translateY(30px) rotate(-180deg);
      transform: translateX(30px) translateY(30px) rotate(-180deg);
    }
    75% {
      -webkit-transform: translateX(0) translateY(30px) rotate(-270deg) scale(0.5);
      transform: translateX(0) translateY(30px) rotate(-270deg) scale(0.5);
    }
    100% {
      -webkit-transform: rotate(-360deg);
      transform: rotate(-360deg);
    }
  }
  
  @keyframes sk-wanderingCube {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    25% {
      -webkit-transform: translateX(30px) rotate(-90deg) scale(0.5);
      transform: translateX(30px) rotate(-90deg) scale(0.5);
    }
    50% {
      /* Hack to make FF rotate in the right direction */
      -webkit-transform: translateX(30px) translateY(30px) rotate(-179deg);
      transform: translateX(30px) translateY(30px) rotate(-179deg);
    }
    50.1% {
      -webkit-transform: translateX(30px) translateY(30px) rotate(-180deg);
      transform: translateX(30px) translateY(30px) rotate(-180deg);
    }
    75% {
      -webkit-transform: translateX(0) translateY(30px) rotate(-270deg) scale(0.5);
      transform: translateX(0) translateY(30px) rotate(-270deg) scale(0.5);
    }
    100% {
      -webkit-transform: rotate(-360deg);
      transform: rotate(-360deg);
    }
  }
  
  /*
   *  Usage:
   *
        <div class="sk-spinner sk-spinner-pulse"></div>
   *
   */
  .sk-spinner-pulse {
    width: 40px;
    height: 40px;
    margin: 0;
    background-color: #379392;
    border-radius: 100%;
    -webkit-animation: sk-pulseScaleOut 1.0s infinite ease-in-out;
    animation: sk-pulseScaleOut 1.0s infinite ease-in-out;
  }
  
  @-webkit-keyframes sk-pulseScaleOut {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 0;
    }
  }
  
  @keyframes sk-pulseScaleOut {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 0;
    }
  }
  
  /*
   *  Usage:
   *
        <div class="sk-chasing-dots">
          <div class="sk-child sk-dot1"></div>
          <div class="sk-child sk-dot2"></div>
        </div>
   *
   */
  .sk-chasing-dots {
    margin: 0;
    width: 40px;
    height: 40px;
    position: relative;
    text-align: center;
    -webkit-animation: sk-chasingDotsRotate 2s infinite linear;
    animation: sk-chasingDotsRotate 2s infinite linear;
  }
  
  .sk-chasing-dots .sk-child {
    width: 60%;
    height: 60%;
    display: inline-block;
    position: absolute;
    top: 0;
    background-color: #379392;
    border-radius: 100%;
    -webkit-animation: sk-chasingDotsBounce 2s infinite ease-in-out;
    animation: sk-chasingDotsBounce 2s infinite ease-in-out;
  }
  
  .sk-chasing-dots .sk-dot2 {
    top: auto;
    bottom: 0;
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
  
  @-webkit-keyframes sk-chasingDotsRotate {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @keyframes sk-chasingDotsRotate {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @-webkit-keyframes sk-chasingDotsBounce {
    0%, 100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    50% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  
  @keyframes sk-chasingDotsBounce {
    0%, 100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    50% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  
  /*
   *  Usage:
   *
        <div class="sk-three-bounce">
          <div class="sk-child sk-bounce1"></div>
          <div class="sk-child sk-bounce2"></div>
          <div class="sk-child sk-bounce3"></div>
        </div>
   *
   */
  .sk-three-bounce {
    margin: 0;
    width: 80px;
    text-align: center;
  }
  
  .sk-three-bounce .sk-child {
    width: 20px;
    height: 20px;
    background-color: #379392;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
    animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
  }
  
  .sk-three-bounce .sk-bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  
  .sk-three-bounce .sk-bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
  
  @-webkit-keyframes sk-three-bounce {
    0%, 80%, 100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  
  @keyframes sk-three-bounce {
    0%, 80%, 100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  
  /*
   *  Usage:
   *
        <div class="sk-circle">
          <div class="sk-circle1 sk-child"></div>
          <div class="sk-circle2 sk-child"></div>
          <div class="sk-circle3 sk-child"></div>
          <div class="sk-circle4 sk-child"></div>
          <div class="sk-circle5 sk-child"></div>
          <div class="sk-circle6 sk-child"></div>
          <div class="sk-circle7 sk-child"></div>
          <div class="sk-circle8 sk-child"></div>
          <div class="sk-circle9 sk-child"></div>
          <div class="sk-circle10 sk-child"></div>
          <div class="sk-circle11 sk-child"></div>
          <div class="sk-circle12 sk-child"></div>
        </div>
   *
   */
  .sk-circle {
    margin: 0;
    width: 40px;
    height: 40px;
    position: relative;
  }
  
  .sk-circle .sk-child {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  
  .sk-circle .sk-child:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: #379392;
    border-radius: 100%;
    -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
    animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
  }
  
  .sk-circle .sk-circle2 {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
  }
  
  .sk-circle .sk-circle3 {
    -webkit-transform: rotate(60deg);
    transform: rotate(60deg);
  }
  
  .sk-circle .sk-circle4 {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  
  .sk-circle .sk-circle5 {
    -webkit-transform: rotate(120deg);
    transform: rotate(120deg);
  }
  
  .sk-circle .sk-circle6 {
    -webkit-transform: rotate(150deg);
    transform: rotate(150deg);
  }
  
  .sk-circle .sk-circle7 {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  
  .sk-circle .sk-circle8 {
    -webkit-transform: rotate(210deg);
    transform: rotate(210deg);
  }
  
  .sk-circle .sk-circle9 {
    -webkit-transform: rotate(240deg);
    transform: rotate(240deg);
  }
  
  .sk-circle .sk-circle10 {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
  
  .sk-circle .sk-circle11 {
    -webkit-transform: rotate(300deg);
    transform: rotate(300deg);
  }
  
  .sk-circle .sk-circle12 {
    -webkit-transform: rotate(330deg);
    transform: rotate(330deg);
  }
  
  .sk-circle .sk-circle2:before {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }
  
  .sk-circle .sk-circle3:before {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
  
  .sk-circle .sk-circle4:before {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }
  
  .sk-circle .sk-circle5:before {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }
  
  .sk-circle .sk-circle6:before {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
  }
  
  .sk-circle .sk-circle7:before {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
  }
  
  .sk-circle .sk-circle8:before {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
  }
  
  .sk-circle .sk-circle9:before {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
  }
  
  .sk-circle .sk-circle10:before {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
  }
  
  .sk-circle .sk-circle11:before {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
  }
  
  .sk-circle .sk-circle12:before {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
  }
  
  @-webkit-keyframes sk-circleBounceDelay {
    0%, 80%, 100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  
  @keyframes sk-circleBounceDelay {
    0%, 80%, 100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  
  /*
   *  Usage:
   *
        <div class="sk-cube-grid">
          <div class="sk-cube sk-cube1"></div>
          <div class="sk-cube sk-cube2"></div>
          <div class="sk-cube sk-cube3"></div>
          <div class="sk-cube sk-cube4"></div>
          <div class="sk-cube sk-cube5"></div>
          <div class="sk-cube sk-cube6"></div>
          <div class="sk-cube sk-cube7"></div>
          <div class="sk-cube sk-cube8"></div>
          <div class="sk-cube sk-cube9"></div>
        </div>
   *
   */
  .sk-cube-grid {
    width: 40px;
    height: 40px;
    margin: 0;
    /*
     * Spinner positions
     * 1 2 3
     * 4 5 6
     * 7 8 9
     */
  }
  
  .sk-cube-grid .sk-cube {
    width: 33.33%;
    height: 33.33%;
    background-color: #379392;
    float: left;
    -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
    animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  }
  
  .sk-cube-grid .sk-cube1 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }
  
  .sk-cube-grid .sk-cube2 {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }
  
  .sk-cube-grid .sk-cube3 {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
  }
  
  .sk-cube-grid .sk-cube4 {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
  }
  
  .sk-cube-grid .sk-cube5 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }
  
  .sk-cube-grid .sk-cube6 {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }
  
  .sk-cube-grid .sk-cube7 {
    -webkit-animation-delay: 0.0s;
    animation-delay: 0.0s;
  }
  
  .sk-cube-grid .sk-cube8 {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
  }
  
  .sk-cube-grid .sk-cube9 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }
  
  @-webkit-keyframes sk-cubeGridScaleDelay {
    0%, 70%, 100% {
      -webkit-transform: scale3D(1, 1, 1);
      transform: scale3D(1, 1, 1);
    }
    35% {
      -webkit-transform: scale3D(0, 0, 1);
      transform: scale3D(0, 0, 1);
    }
  }
  
  @keyframes sk-cubeGridScaleDelay {
    0%, 70%, 100% {
      -webkit-transform: scale3D(1, 1, 1);
      transform: scale3D(1, 1, 1);
    }
    35% {
      -webkit-transform: scale3D(0, 0, 1);
      transform: scale3D(0, 0, 1);
    }
  }
  
  /*
   *  Usage:
   *
        <div class="sk-fading-circle">
          <div class="sk-circle1 sk-circle"></div>
          <div class="sk-circle2 sk-circle"></div>
          <div class="sk-circle3 sk-circle"></div>
          <div class="sk-circle4 sk-circle"></div>
          <div class="sk-circle5 sk-circle"></div>
          <div class="sk-circle6 sk-circle"></div>
          <div class="sk-circle7 sk-circle"></div>
          <div class="sk-circle8 sk-circle"></div>
          <div class="sk-circle9 sk-circle"></div>
          <div class="sk-circle10 sk-circle"></div>
          <div class="sk-circle11 sk-circle"></div>
          <div class="sk-circle12 sk-circle"></div>
        </div>
   *
   */
  .sk-fading-circle {
    margin: 0;
    width: 40px;
    height: 40px;
    position: relative;
  }
  
  .sk-fading-circle .sk-circle {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  
  .sk-fading-circle .sk-circle:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: #379392;
    border-radius: 100%;
    -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
    animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  }
  
  .sk-fading-circle .sk-circle2 {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
  }
  
  .sk-fading-circle .sk-circle3 {
    -webkit-transform: rotate(60deg);
    transform: rotate(60deg);
  }
  
  .sk-fading-circle .sk-circle4 {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  
  .sk-fading-circle .sk-circle5 {
    -webkit-transform: rotate(120deg);
    transform: rotate(120deg);
  }
  
  .sk-fading-circle .sk-circle6 {
    -webkit-transform: rotate(150deg);
    transform: rotate(150deg);
  }
  
  .sk-fading-circle .sk-circle7 {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  
  .sk-fading-circle .sk-circle8 {
    -webkit-transform: rotate(210deg);
    transform: rotate(210deg);
  }
  
  .sk-fading-circle .sk-circle9 {
    -webkit-transform: rotate(240deg);
    transform: rotate(240deg);
  }
  
  .sk-fading-circle .sk-circle10 {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
  
  .sk-fading-circle .sk-circle11 {
    -webkit-transform: rotate(300deg);
    transform: rotate(300deg);
  }
  
  .sk-fading-circle .sk-circle12 {
    -webkit-transform: rotate(330deg);
    transform: rotate(330deg);
  }
  
  .sk-fading-circle .sk-circle2:before {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }
  
  .sk-fading-circle .sk-circle3:before {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
  
  .sk-fading-circle .sk-circle4:before {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }
  
  .sk-fading-circle .sk-circle5:before {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }
  
  .sk-fading-circle .sk-circle6:before {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
  }
  
  .sk-fading-circle .sk-circle7:before {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
  }
  
  .sk-fading-circle .sk-circle8:before {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
  }
  
  .sk-fading-circle .sk-circle9:before {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
  }
  
  .sk-fading-circle .sk-circle10:before {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
  }
  
  .sk-fading-circle .sk-circle11:before {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
  }
  
  .sk-fading-circle .sk-circle12:before {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
  }
  
  @-webkit-keyframes sk-circleFadeDelay {
    0%, 39%, 100% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
  }
  
  @keyframes sk-circleFadeDelay {
    0%, 39%, 100% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
  }
  
  /*
   *  Usage:
   *
        <div class="sk-folding-cube">
          <div class="sk-cube1 sk-cube"></div>
          <div class="sk-cube2 sk-cube"></div>
          <div class="sk-cube4 sk-cube"></div>
          <div class="sk-cube3 sk-cube"></div>
        </div>
   *
   */
  .sk-folding-cube {
    margin: 0;
    width: 40px;
    height: 40px;
    position: relative;
    -webkit-transform: rotateZ(45deg);
    transform: rotateZ(45deg);
  }
  
  .sk-folding-cube .sk-cube {
    float: left;
    width: 50%;
    height: 50%;
    position: relative;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  
  .sk-folding-cube .sk-cube:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #379392;
    -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
    animation: sk-foldCubeAngle 2.4s infinite linear both;
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
  
  .sk-folding-cube .sk-cube2 {
    -webkit-transform: scale(1.1) rotateZ(90deg);
    transform: scale(1.1) rotateZ(90deg);
  }
  
  .sk-folding-cube .sk-cube3 {
    -webkit-transform: scale(1.1) rotateZ(180deg);
    transform: scale(1.1) rotateZ(180deg);
  }
  
  .sk-folding-cube .sk-cube4 {
    -webkit-transform: scale(1.1) rotateZ(270deg);
    transform: scale(1.1) rotateZ(270deg);
  }
  
  .sk-folding-cube .sk-cube2:before {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }
  
  .sk-folding-cube .sk-cube3:before {
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
  }
  
  .sk-folding-cube .sk-cube4:before {
    -webkit-animation-delay: 0.9s;
    animation-delay: 0.9s;
  }
  
  @-webkit-keyframes sk-foldCubeAngle {
    0%, 10% {
      -webkit-transform: perspective(140px) rotateX(-180deg);
      transform: perspective(140px) rotateX(-180deg);
      opacity: 0;
    }
    25%, 75% {
      -webkit-transform: perspective(140px) rotateX(0deg);
      transform: perspective(140px) rotateX(0deg);
      opacity: 1;
    }
    90%, 100% {
      -webkit-transform: perspective(140px) rotateY(180deg);
      transform: perspective(140px) rotateY(180deg);
      opacity: 0;
    }
  }
  
  @keyframes sk-foldCubeAngle {
    0%, 10% {
      -webkit-transform: perspective(140px) rotateX(-180deg);
      transform: perspective(140px) rotateX(-180deg);
      opacity: 0;
    }
    25%, 75% {
      -webkit-transform: perspective(140px) rotateX(0deg);
      transform: perspective(140px) rotateX(0deg);
      opacity: 1;
    }
    90%, 100% {
      -webkit-transform: perspective(140px) rotateY(180deg);
      transform: perspective(140px) rotateY(180deg);
      opacity: 0;
    }
  }
  
  /*
  
  =====================
  STYLE SWITCHER FOR DEMO
  =====================
  
  */
  #style-switch-button {
    position: fixed;
    top: 120px;
    right: 0px;
    border-radius: 0;
    z-index: 2;
  }
  
  #style-switch {
    width: 300px;
    padding: 20px;
    position: fixed;
    top: 160px;
    right: 0px;
    background: #fff;
    border: solid 1px #ced4da;
    z-index: 2000;
  }
  
  #style-switch h4 {
    color: #495057;
  }
  
  /* ========================================= 
     THEMING OF BOOTSTRAP COMPONENTS           
     ========================================= */
  /*
   * 1. NAVBAR
   */
  .navbar {
    padding: 0.5rem 1rem;
  }
  
  .navbar-brand {
    display: inline-block;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
  }
  
  .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    border: 1px solid transparent;
    border-radius: 0;
  }
  
  .navbar-light .navbar-brand {
    color: rgba(0, 0, 0, 0.9);
  }
  
  .navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
    color: rgba(0, 0, 0, 0.9);
  }
  
  .navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 0.5);
  }
  
  .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    color: rgba(0, 0, 0, 0.7);
  }
  
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3);
  }
  
  .navbar-light .navbar-nav .show > .nav-link,
  .navbar-light .navbar-nav .active > .nav-link,
  .navbar-light .navbar-nav .nav-link.show,
  .navbar-light .navbar-nav .nav-link.active {
    color: rgba(0, 0, 0, 0.9);
  }
  
  .navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.1);
  }
  
  .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  }
  
  .navbar-light .navbar-text {
    color: rgba(0, 0, 0, 0.5);
  }
  
  .navbar-dark .navbar-brand {
    color: #fff;
  }
  
  .navbar-dark .navbar-brand:focus, .navbar-dark .navbar-brand:hover {
    color: #fff;
  }
  
  .navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.5);
  }
  
  .navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
    color: rgba(255, 255, 255, 0.75);
  }
  
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25);
  }
  
  .navbar-dark .navbar-nav .show > .nav-link,
  .navbar-dark .navbar-nav .active > .nav-link,
  .navbar-dark .navbar-nav .nav-link.show,
  .navbar-dark .navbar-nav .nav-link.active {
    color: #fff;
  }
  
  .navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, 0.5);
    border-color: rgba(255, 255, 255, 0.1);
  }
  
  .navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  }
  
  .navbar-dark .navbar-text {
    color: rgba(255, 255, 255, 0.5);
  }
  
  /*
   * 2. BUTTONS
   */
  .btn {
    font-weight: 400;
    border: 1px solid transparent;
    padding: 0.45rem 0.75rem;
    font-size: 0.9rem;
    line-height: 1.5;
    border-radius: 0;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  }
  
  .btn:focus, .btn.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(55, 147, 146, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(55, 147, 146, 0.25);
  }
  
  .btn.disabled, .btn:disabled {
    opacity: .65;
  }
  
  .btn:not([disabled]):not(.disabled):active, .btn:not([disabled]):not(.disabled).active {
    background-image: none;
  }
  
  .btn-primary {
    color: color-yiq(#379392);
    background-color: #379392;
    border-color: #379392;
  }
  
  .btn-primary:hover {
    color: color-yiq(#2d7776);
    background-color: #2d7776;
    border-color: #296e6d;
  }
  
  .btn-primary:focus, .btn-primary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(55, 147, 146, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(55, 147, 146, 0.5);
  }
  
  .btn-primary.disabled, .btn-primary:disabled {
    background-color: #379392;
    border-color: #379392;
  }
  
  .btn-primary:not([disabled]):not(.disabled):active, .btn-primary:not([disabled]):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: color-yiq(#296e6d);
    background-color: #296e6d;
    border-color: #266564;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(55, 147, 146, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(55, 147, 146, 0.5);
  }
  
  .btn-secondary {
    color: color-yiq(#868e96);
    background-color: #868e96;
    border-color: #868e96;
  }
  
  .btn-secondary:hover {
    color: color-yiq(#727b84);
    background-color: #727b84;
    border-color: #6c757d;
  }
  
  .btn-secondary:focus, .btn-secondary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(134, 142, 150, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(134, 142, 150, 0.5);
  }
  
  .btn-secondary.disabled, .btn-secondary:disabled {
    background-color: #868e96;
    border-color: #868e96;
  }
  
  .btn-secondary:not([disabled]):not(.disabled):active, .btn-secondary:not([disabled]):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: color-yiq(#6c757d);
    background-color: #6c757d;
    border-color: #666e76;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(134, 142, 150, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(134, 142, 150, 0.5);
  }
  
  .btn-success {
    color: color-yiq(#28a745);
    background-color: #28a745;
    border-color: #28a745;
  }
  
  .btn-success:hover {
    color: color-yiq(#218838);
    background-color: #218838;
    border-color: #1e7e34;
  }
  
  .btn-success:focus, .btn-success.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
  }
  
  .btn-success.disabled, .btn-success:disabled {
    background-color: #28a745;
    border-color: #28a745;
  }
  
  .btn-success:not([disabled]):not(.disabled):active, .btn-success:not([disabled]):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: color-yiq(#1e7e34);
    background-color: #1e7e34;
    border-color: #1c7430;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
  }
  
  .btn-info {
    color: color-yiq(#17a2b8);
    background-color: #17a2b8;
    border-color: #17a2b8;
  }
  
  .btn-info:hover {
    color: color-yiq(#138496);
    background-color: #138496;
    border-color: #117a8b;
  }
  
  .btn-info:focus, .btn-info.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
  }
  
  .btn-info.disabled, .btn-info:disabled {
    background-color: #17a2b8;
    border-color: #17a2b8;
  }
  
  .btn-info:not([disabled]):not(.disabled):active, .btn-info:not([disabled]):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: color-yiq(#117a8b);
    background-color: #117a8b;
    border-color: #10707f;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
  }
  
  .btn-warning {
    color: color-yiq(#ffc107);
    background-color: #ffc107;
    border-color: #ffc107;
  }
  
  .btn-warning:hover {
    color: color-yiq(#e0a800);
    background-color: #e0a800;
    border-color: #d39e00;
  }
  
  .btn-warning:focus, .btn-warning.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
  }
  
  .btn-warning.disabled, .btn-warning:disabled {
    background-color: #ffc107;
    border-color: #ffc107;
  }
  
  .btn-warning:not([disabled]):not(.disabled):active, .btn-warning:not([disabled]):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: color-yiq(#d39e00);
    background-color: #d39e00;
    border-color: #c69500;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
  }
  
  .btn-danger {
    color: color-yiq(#dc3545);
    background-color: #dc3545;
    border-color: #dc3545;
  }
  
  .btn-danger:hover {
    color: color-yiq(#c82333);
    background-color: #c82333;
    border-color: #bd2130;
  }
  
  .btn-danger:focus, .btn-danger.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
  }
  
  .btn-danger.disabled, .btn-danger:disabled {
    background-color: #dc3545;
    border-color: #dc3545;
  }
  
  .btn-danger:not([disabled]):not(.disabled):active, .btn-danger:not([disabled]):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: color-yiq(#bd2130);
    background-color: #bd2130;
    border-color: #b21f2d;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
  }
  
  .btn-light {
    color: color-yiq(#f8f9fa);
    background-color: #f8f9fa;
    border-color: #f8f9fa;
  }
  
  .btn-light:hover {
    color: color-yiq(#e2e6ea);
    background-color: #e2e6ea;
    border-color: #dae0e5;
  }
  
  .btn-light:focus, .btn-light.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  }
  
  .btn-light.disabled, .btn-light:disabled {
    background-color: #f8f9fa;
    border-color: #f8f9fa;
  }
  
  .btn-light:not([disabled]):not(.disabled):active, .btn-light:not([disabled]):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: color-yiq(#dae0e5);
    background-color: #dae0e5;
    border-color: #d3d9df;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  }
  
  .btn-dark {
    color: color-yiq(#343a40);
    background-color: #343a40;
    border-color: #343a40;
  }
  
  .btn-dark:hover {
    color: color-yiq(#23272b);
    background-color: #23272b;
    border-color: #1d2124;
  }
  
  .btn-dark:focus, .btn-dark.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
  }
  
  .btn-dark.disabled, .btn-dark:disabled {
    background-color: #343a40;
    border-color: #343a40;
  }
  
  .btn-dark:not([disabled]):not(.disabled):active, .btn-dark:not([disabled]):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: color-yiq(#1d2124);
    background-color: #1d2124;
    border-color: #171a1d;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
  }
  
  .btn-default {
    color: color-yiq(#ced4da);
    background-color: #ced4da;
    border-color: #ced4da;
  }
  
  .btn-default:hover {
    color: color-yiq(#b8c1ca);
    background-color: #b8c1ca;
    border-color: #b1bbc4;
  }
  
  .btn-default:focus, .btn-default.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(206, 212, 218, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(206, 212, 218, 0.5);
  }
  
  .btn-default.disabled, .btn-default:disabled {
    background-color: #ced4da;
    border-color: #ced4da;
  }
  
  .btn-default:not([disabled]):not(.disabled):active, .btn-default:not([disabled]):not(.disabled).active,
  .show > .btn-default.dropdown-toggle {
    color: color-yiq(#b1bbc4);
    background-color: #b1bbc4;
    border-color: #aab4bf;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(206, 212, 218, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(206, 212, 218, 0.5);
  }
  
  .btn-outline-primary {
    color: #379392;
    background-color: transparent;
    background-image: none;
    border-color: #379392;
  }
  
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #379392;
    border-color: #379392;
  }
  
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(55, 147, 146, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(55, 147, 146, 0.5);
  }
  
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #379392;
    background-color: transparent;
  }
  
  .btn-outline-primary:not([disabled]):not(.disabled):active, .btn-outline-primary:not([disabled]):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #379392;
    border-color: #379392;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(55, 147, 146, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(55, 147, 146, 0.5);
  }
  
  .btn-outline-secondary {
    color: #868e96;
    background-color: transparent;
    background-image: none;
    border-color: #868e96;
  }
  
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #868e96;
    border-color: #868e96;
  }
  
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(134, 142, 150, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(134, 142, 150, 0.5);
  }
  
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #868e96;
    background-color: transparent;
  }
  
  .btn-outline-secondary:not([disabled]):not(.disabled):active, .btn-outline-secondary:not([disabled]):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #868e96;
    border-color: #868e96;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(134, 142, 150, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(134, 142, 150, 0.5);
  }
  
  .btn-outline-success {
    color: #28a745;
    background-color: transparent;
    background-image: none;
    border-color: #28a745;
  }
  
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
  }
  
  .btn-outline-success:focus, .btn-outline-success.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
  }
  
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent;
  }
  
  .btn-outline-success:not([disabled]):not(.disabled):active, .btn-outline-success:not([disabled]):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
  }
  
  .btn-outline-info {
    color: #17a2b8;
    background-color: transparent;
    background-image: none;
    border-color: #17a2b8;
  }
  
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
  }
  
  .btn-outline-info:focus, .btn-outline-info.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
  }
  
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent;
  }
  
  .btn-outline-info:not([disabled]):not(.disabled):active, .btn-outline-info:not([disabled]):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
  }
  
  .btn-outline-warning {
    color: #ffc107;
    background-color: transparent;
    background-image: none;
    border-color: #ffc107;
  }
  
  .btn-outline-warning:hover {
    color: #fff;
    background-color: #ffc107;
    border-color: #ffc107;
  }
  
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
  }
  
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent;
  }
  
  .btn-outline-warning:not([disabled]):not(.disabled):active, .btn-outline-warning:not([disabled]):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #fff;
    background-color: #ffc107;
    border-color: #ffc107;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
  }
  
  .btn-outline-danger {
    color: #dc3545;
    background-color: transparent;
    background-image: none;
    border-color: #dc3545;
  }
  
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
  }
  
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
  }
  
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent;
  }
  
  .btn-outline-danger:not([disabled]):not(.disabled):active, .btn-outline-danger:not([disabled]):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
  }
  
  .btn-outline-light {
    color: #f8f9fa;
    background-color: transparent;
    background-image: none;
    border-color: #f8f9fa;
  }
  
  .btn-outline-light:hover {
    color: #fff;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
  }
  
  .btn-outline-light:focus, .btn-outline-light.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  }
  
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent;
  }
  
  .btn-outline-light:not([disabled]):not(.disabled):active, .btn-outline-light:not([disabled]):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #fff;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  }
  
  .btn-outline-dark {
    color: #343a40;
    background-color: transparent;
    background-image: none;
    border-color: #343a40;
  }
  
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
  }
  
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
  }
  
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent;
  }
  
  .btn-outline-dark:not([disabled]):not(.disabled):active, .btn-outline-dark:not([disabled]):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
  }
  
  .btn-lg {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0;
  }
  
  .btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0;
  }
  
  /*
   * 3. TYPE  
   */
  body {
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
  }
  
  a {
    color: #379392;
    text-decoration: none;
  }
  
  a:focus, a:hover {
    color: #225b5b;
    text-decoration: underline;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    margin-bottom: 0.5rem;
    font-family: inherit;
    font-weight: 700;
    line-height: 1.1;
    color: inherit;
  }
  
  h1,
  .h1 {
    font-size: 1.5rem;
  }
  
  h2,
  .h2 {
    font-size: 1.3rem;
  }
  
  h3,
  .h3 {
    font-size: 1.2rem;
  }
  
  h4,
  .h4 {
    font-size: 1.1rem;
  }
  
  h5,
  .h5 {
    font-size: 1rem;
  }
  
  h6,
  .h6 {
    font-size: 0.9rem;
  }
  
  .lead {
    font-size: 1.25rem;
    font-weight: 300;
  }
  
  .display-1 {
    font-size: 6rem;
    font-weight: 300;
    line-height: 1.1;
  }
  
  .display-2 {
    font-size: 5.5rem;
    font-weight: 300;
    line-height: 1.1;
  }
  
  .display-3 {
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.1;
  }
  
  .display-4 {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.1;
  }
  
  hr {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  
  small,
  .small {
    font-size: 80%;
    font-weight: 400;
  }
  
  mark,
  .mark {
    padding: 0.2em;
    background-color: #fcf8e3;
  }
  
  .blockquote {
    padding: 0.5rem 1rem;
    margin-bottom: 2rem;
    font-size: 1.25rem;
    border-left: 5px solid #379392;
  }
  
  .blockquote-footer {
    color: #868e96;
  }
  
  .blockquote-footer::before {
    content: "\2014 \00A0";
  }
  
  .text-primary {
    color: #379392 !important;
  }
  
  a.text-primary:focus, a.text-primary:hover {
    color: #296e6d !important;
  }
  
  /*
   * 4. PAGINATION
   */
  .page-item:first-child .page-link {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  
  .page-item:last-child .page-link {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  
  .page-item.active .page-link {
    color: #fff;
    background-color: #379392;
    border-color: #379392;
  }
  
  .page-item.disabled .page-link {
    color: #868e96;
    background-color: #fff;
    border-color: #ddd;
  }
  
  .page-link {
    padding: 0.5rem 0.75rem;
    line-height: 1.25;
    color: #379392;
    background-color: #fff;
    border: 1px solid #ddd;
  }
  
  .page-link:focus, .page-link:hover {
    color: #225b5b;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #ddd;
    outline: 0;
  }
  
  .pagination-lg .page-link {
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem;
    line-height: 1.5;
  }
  
  .pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
  }
  
  .pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
  }
  
  .pagination-sm .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
  }
  
  .pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem;
  }
  
  .pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
  }
  
  /*
  * 5. DROPDOWNS
  */
  .dropdown-menu {
    z-index: 1000;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
  }
  
  .dropdown-item {
    padding: 0.25rem 1.5rem;
    color: #212529;
  }
  
  .dropdown-item:focus, .dropdown-item:hover {
    color: #16181b;
    background-color: #f8f9fa;
  }
  
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    background-color: #379392;
  }
  
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #868e96;
  }
  
  .dropdown-header {
    padding: 0.5rem 1.5rem;
    font-size: 0.875rem;
    color: #868e96;
  }
  
  /*
  * 6. UTILITIES
  */
  .bg-primary {
    background-color: #379392 !important;
  }
  
  a.bg-primary:focus, a.bg-primary:hover {
    background-color: #296e6d !important;
  }
  
  .bg-secondary {
    background-color: #868e96 !important;
  }
  
  a.bg-secondary:focus, a.bg-secondary:hover {
    background-color: #6c757d !important;
  }
  
  .bg-success {
    background-color: #28a745 !important;
  }
  
  a.bg-success:focus, a.bg-success:hover {
    background-color: #1e7e34 !important;
  }
  
  .bg-info {
    background-color: #17a2b8 !important;
  }
  
  a.bg-info:focus, a.bg-info:hover {
    background-color: #117a8b !important;
  }
  
  .bg-warning {
    background-color: #ffc107 !important;
  }
  
  a.bg-warning:focus, a.bg-warning:hover {
    background-color: #d39e00 !important;
  }
  
  .bg-danger {
    background-color: #dc3545 !important;
  }
  
  a.bg-danger:focus, a.bg-danger:hover {
    background-color: #bd2130 !important;
  }
  
  .bg-light {
    background-color: #f8f9fa !important;
  }
  
  a.bg-light:focus, a.bg-light:hover {
    background-color: #dae0e5 !important;
  }
  
  .bg-dark {
    background-color: #343a40 !important;
  }
  
  a.bg-dark:focus, a.bg-dark:hover {
    background-color: #1d2124 !important;
  }
  
  .border-primary {
    border-color: #379392 !important;
  }
  
  .border-secondary {
    border-color: #868e96 !important;
  }
  
  .border-success {
    border-color: #28a745 !important;
  }
  
  .border-info {
    border-color: #17a2b8 !important;
  }
  
  .border-warning {
    border-color: #ffc107 !important;
  }
  
  .border-danger {
    border-color: #dc3545 !important;
  }
  
  .border-light {
    border-color: #f8f9fa !important;
  }
  
  .border-dark {
    border-color: #343a40 !important;
  }
  
  .text-primary {
    color: #379392 !important;
  }
  
  a.text-primary:focus, a.text-primary:hover {
    color: #296e6d !important;
  }
  
  .text-secondary {
    color: #868e96 !important;
  }
  
  a.text-secondary:focus, a.text-secondary:hover {
    color: #6c757d !important;
  }
  
  .text-success {
    color: #28a745 !important;
  }
  
  a.text-success:focus, a.text-success:hover {
    color: #1e7e34 !important;
  }
  
  .text-info {
    color: #17a2b8 !important;
  }
  
  a.text-info:focus, a.text-info:hover {
    color: #117a8b !important;
  }
  
  .text-warning {
    color: #ffc107 !important;
  }
  
  a.text-warning:focus, a.text-warning:hover {
    color: #d39e00 !important;
  }
  
  .text-danger {
    color: #dc3545 !important;
  }
  
  a.text-danger:focus, a.text-danger:hover {
    color: #bd2130 !important;
  }
  
  .text-light {
    color: #f8f9fa !important;
  }
  
  a.text-light:focus, a.text-light:hover {
    color: #dae0e5 !important;
  }
  
  .text-dark {
    color: #343a40 !important;
  }
  
  a.text-dark:focus, a.text-dark:hover {
    color: #1d2124 !important;
  }
  
  .badge-primary {
    color: color-yiq(#379392);
    background-color: #379392;
  }
  
  .badge-primary[href]:focus, .badge-primary[href]:hover {
    color: color-yiq(#379392);
    text-decoration: none;
    background-color: #296e6d;
  }
  
  .badge-secondary {
    color: color-yiq(#868e96);
    background-color: #868e96;
  }
  
  .badge-secondary[href]:focus, .badge-secondary[href]:hover {
    color: color-yiq(#868e96);
    text-decoration: none;
    background-color: #6c757d;
  }
  
  .badge-success {
    color: color-yiq(#28a745);
    background-color: #28a745;
  }
  
  .badge-success[href]:focus, .badge-success[href]:hover {
    color: color-yiq(#28a745);
    text-decoration: none;
    background-color: #1e7e34;
  }
  
  .badge-info {
    color: color-yiq(#17a2b8);
    background-color: #17a2b8;
  }
  
  .badge-info[href]:focus, .badge-info[href]:hover {
    color: color-yiq(#17a2b8);
    text-decoration: none;
    background-color: #117a8b;
  }
  
  .badge-warning {
    color: color-yiq(#ffc107);
    background-color: #ffc107;
  }
  
  .badge-warning[href]:focus, .badge-warning[href]:hover {
    color: color-yiq(#ffc107);
    text-decoration: none;
    background-color: #d39e00;
  }
  
  .badge-danger {
    color: color-yiq(#dc3545);
    background-color: #dc3545;
  }
  
  .badge-danger[href]:focus, .badge-danger[href]:hover {
    color: color-yiq(#dc3545);
    text-decoration: none;
    background-color: #bd2130;
  }
  
  .badge-light {
    color: color-yiq(#f8f9fa);
    background-color: #f8f9fa;
  }
  
  .badge-light[href]:focus, .badge-light[href]:hover {
    color: color-yiq(#f8f9fa);
    text-decoration: none;
    background-color: #dae0e5;
  }
  
  .badge-dark {
    color: color-yiq(#343a40);
    background-color: #343a40;
  }
  
  .badge-dark[href]:focus, .badge-dark[href]:hover {
    color: color-yiq(#343a40);
    text-decoration: none;
    background-color: #1d2124;
  }
  
  /*
  * 7.CODE
  */
  code {
    padding: 0.2rem 0.4rem;
    font-size: 87.5%;
    color: #e83e8c;
    background-color: #f8f9fa;
    border-radius: 0.25rem;
  }
  
  a > code {
    padding: 0;
    color: inherit;
    background-color: inherit;
  }
  
  /*
  * 8. NAV
  */
  .nav-link {
    padding: 0.5rem 1rem;
  }
  
  .nav-link.disabled {
    color: #868e96;
  }
  
  .nav-tabs .nav-item {
    margin-bottom: -1px;
  }
  
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  
  .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    border-color: #e9ecef;
  }
  
  .nav-tabs .nav-link.disabled {
    color: #868e96;
  }
  
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
  }
  
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
  }
  
  .nav-pills .nav-link {
    border-radius: 0.25rem;
  }
  
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #379392;
  }
  
  /*
  * 9. CARD
  */
  .card {
    background-color: #fff;
    border: 0 solid #eee;
    border-radius: 0;
  }
  
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .card-body {
    padding: 1.25rem;
  }
  
  .card-title {
    margin-bottom: 1rem;
  }
  
  .card-subtitle {
    margin-top: -0.5rem;
  }
  
  .card-link + .card-link {
    margin-left: 1.25rem;
  }
  
  .card-header {
    padding: 1rem 1.25rem;
    background-color: #fff;
    border-bottom: 1px solid #eee;
  }
  
  .card-header:first-child {
    border-radius: 0 0 0 0;
  }
  
  .card-header-transparent {
    background-color: rgba(0, 0, 0, 0.3);
    border-bottom: none;
  }
  
  .card-footer {
    padding: 1rem 1.25rem;
    background-color: #f8f9fa;
    border-top: 1px solid #eee;
  }
  
  .card-footer:last-child {
    border-radius: 0 0 0 0;
  }
  
  .card-header-tabs {
    margin-right: -0.625rem;
    margin-bottom: -1rem;
    margin-left: -0.625rem;
    border-bottom: 0;
  }
  
  .card-header-pills {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
  }
  
  .card-img-overlay {
    padding: 1.25rem;
  }
  
  .card-img-overlay-opacity {
    background: rgba(0, 0, 0, 0.2);
  }
  
  .card-img {
    border-radius: 0;
  }
  
  .card-img-top {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  
  .card-img-bottom {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .card-deck .card {
    margin-bottom: 15px;
  }
  
  @media (min-width: 576px) {
    .card-deck {
      margin-right: -15px;
      margin-left: -15px;
    }
    .card-deck .card {
      margin-right: 15px;
      margin-left: 15px;
    }
  }